export const advertisementChannelData = ['Facebook', 'Youtube', 'Other']

export const totalProductionBudgetData = ['High', 'Medium', 'Low', 'N/A']

export const TOTAL_PRODUCTION_BUDGET = [
  {
    value: 'high',
    label: 'High',
  },
  {
    value: 'medium',
    label: 'Medium',
  },
  {
    value: 'low',
    label: 'Low',
  },
  {
    value: 'n/a',
    label: 'N/A',
  },
]
