import React from 'react'
import './RoundButton.scss'
import classNames from 'classnames'
import { ReactComponent as ButtonSvg } from 'assets/images/arrow.svg'

class RoundButton extends React.Component {
  render() {
    const { children, className, onClick, disabled, navToPath } = this.props

    let { icon = <ButtonSvg className="round-button" /> } = this.props

    return (
      <div
        className={classNames('RoundButton', className, {
          disabled,
        })}
        onClick={() => (!disabled ? onClick() : null)}>
        {children}
        <div className="round-button-wrapper">
          <a href={navToPath}>{icon}</a>
        </div>
        <div className="button-outline"></div>
      </div>
    )
  }
}

export default RoundButton
