import BorderlessButton from '../../../components/BorderlessButton/index'
import Button from '../../../components/Button/index'
import CurrencyInput from 'react-currency-input'
import React from 'react'
import Select from 'components/Select'
import { TOTAL_PRODUCTION_BUDGET } from '../MarketingPages/SynchronizerMarketingData'
import TextInput from '../../../components/TextInput/index'

class SynchronizerPageThree extends React.Component {
  get answers() {
    return this.props.answers
  }

  get onChange() {
    return this.props.onChange
  }

  get valid() {
    const { totalProductionBudget, totalMusicBudget } = this.answers

    return totalProductionBudget && totalMusicBudget
  }

  onNewMusicBudget = (_, _1, floatValue) => {
    this.onChange('totalMusicBudget')(floatValue)
  }

  get totalProductionBudget() {
    return this.answers.totalProductionBudget
  }

  render() {
    const { nextOnClick, prevOnClick } = this.props

    return (
      <div className="SynchronizerPage" id="SynchronizerMarketingPage-3">
        <div className="meta-description">
          <h1>Synchronizer</h1>
        </div>
        <div className="description">Information regarding finance</div>
        <div className="question-container">
          <Select
            onChange={this.onChange('totalProductionBudget')}
            placeholder="Total production budget"
            className="question-wrapper"
            initialValue={this.totalProductionBudget}
            options={TOTAL_PRODUCTION_BUDGET}
            value={this.totalProductionBudget}
            multiSelect={false}
          />

          <div className="question-wrapper currency-wrapper">
            <TextInput placeholder="Total Music Budget" disabled={true} />

            <div className="currency-input-container">
              <CurrencyInput
                value={this.answers.totalMusicBudget}
                onChangeEvent={this.onNewMusicBudget}
                className="currency-input"
                suffix=" kr"
                precision="0"
                decimalSeparator=","
                thousandSeparator="."
              />
              <div className="divider"></div>
            </div>
          </div>

          <div className="button-container">
            <BorderlessButton className="button" onClick={prevOnClick}>
              Previous
            </BorderlessButton>
            <Button
              onClick={nextOnClick}
              className="button"
              disabled={!this.valid}>
              Next
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default SynchronizerPageThree
