import React from 'react'
import './TextInput.scss'
import classNames from 'classnames'
import TextareaAutosize from 'react-textarea-autosize'

class TextInput extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.textInput = React.createRef()
    this.focus = this.focus.bind(this)
    this.blur = this.blur.bind(this)
    this.unFocus = this.unFocus.bind(this)
    this.state = {
      isFocused: false,
    }
  }

  async sleep(msec) {
    return new Promise((resolve) => setTimeout(resolve, msec))
  }

  focus() {
    this.textInput.current.focus()
  }

  blur() {
    this.textInput.current.blur()
  }

  unFocus() {
    this.setState({
      isFocused: false,
    })
  }

  get value() {
    return this.props.value || this.props.initialValue
  }

  setValue(value) {
    this.setState({
      value: value,
    })
  }

  onBlurTextInput(onBlur) {
    if (this.props.isDropdownMultiSelect) {
      return
    }

    this.setState({
      isFocused: false,
    })
    if (onBlur) {
      onBlur()
    }
    this.blur()
  }

  onFocusTextInput(onFocus) {
    this.setState({
      isFocused: true,
    })
    if (onFocus) {
      onFocus()
    }
    this.focus()
  }

  handleTextAreaChange(event, onChange) {
    this.autoExpandTextArea(event.target)
    this.setState({ value: event.target.value })
    if (onChange) {
      onChange(event.target.value)
    }
  }

  handleChange(event, onChange) {
    this.setState({ value: event.target.value })
    if (onChange) {
      onChange(event.target.value)
    }
  }

  renderDropdownArrow(onFocus, onBlur) {
    return (
      <div className="dropdown-arrow">
        <img src={require('assets/images/dropdown-icon.svg')} alt="" />
      </div>
    )
  }

  render() {
    const {
      isDropdown,
      placeholder,
      onFocus,
      onBlur,
      onChange,
      width,
      textType = 'text',
      wrapperClassName,
      inputClassName,
      disabled,
      error,
    } = this.props

    const hasValue =
      this.value !== null &&
      this.value !== undefined &&
      this.value !== '' &&
      ((textType !== 'number' && this.value.length > 0) ||
        textType === 'number')

    return (
      <div
        className={classNames('TextInput', wrapperClassName, {
          disabled,
          error: error && this.value,
          focused: this.state.isFocused,
          hasValue,
        })}
        style={{ width: width ?? null }}>
        <div className="input-container">
          {textType === 'textarea' ? (
            <TextareaAutosize
              disabled={disabled}
              ref={this.textInput}
              value={this.value}
              onChange={(e) => this.handleChange(e, onChange)}
              onBlur={() => this.onBlurTextInput(onBlur)}
              onFocus={() => this.onFocusTextInput(onFocus)}
              placeholder={placeholder}
              type={textType}
              className={classNames(
                'syncin-text-input',
                'syncin-textarea-input',
                inputClassName
              )}
            />
          ) : (
            <input
              autoComplete={'off'}
              disabled={disabled}
              ref={this.textInput}
              value={this.value}
              onChange={(e) => this.handleChange(e, onChange)}
              onBlur={() => this.onBlurTextInput(onBlur)}
              onFocus={() => this.onFocusTextInput(onFocus)}
              placeholder={placeholder}
              type={textType}
              className={classNames('syncin-text-input', inputClassName)}
            />
          )}
          {isDropdown ? this.renderDropdownArrow(onFocus, onBlur) : null}
        </div>

        <div className={classNames('divider')}></div>
      </div>
    )
  }
}

export default TextInput
