import React from 'react'
import './Phone.scss'
import classNames from 'classnames'
import 'react-phone-number-input/style.css'
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'

class Phone extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      focused: false,
    }
  }

  onFocus = () => {
    this.setState({
      isFocused: false,
    })
  }

  onBlur = () => {
    this.setState({
      isFocused: true,
    })
  }

  render() {
    const { className, disabled, value, onChange } = this.props

    return (
      <div
        className={classNames('Phone', className, {
          disabled,
          focused: this.isFocused,
          error:
            value !== undefined &&
            value.length > 0 &&
            !isPossiblePhoneNumber(value),
        })}>
        <PhoneInput
          country="DK"
          defaultCountry="DK"
          placeholder="Phone (Optional)"
          value={value || ''}
          onChange={onChange}
          onFocus={this.onFocus}
          onBlur={this.onFocus}
        />

        <div className={classNames('divider')}></div>
      </div>
    )
  }
}

export default Phone
