import React from 'react'
import Button from '../../../components/Button/index'
import BorderlessButton from '../../../components/BorderlessButton/index'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css' // css for datepicker

class SynchronizerPageTwo extends React.Component {
  get answers() {
    return this.props.answers
  }

  get onChange() {
    return this.props.onChange
  }

  get valid() {
    const { launchDate } = this.answers

    return launchDate ? true : false
  }

  render() {
    const { nextOnClick, prevOnClick } = this.props

    return (
      <div className="SynchronizerPage" id="SynchronizerMarketingPage-2">
        <div className="meta-description">
          <h1>Synchronizer</h1>
        </div>
        <div className="description">Information regarding use</div>
        <div className="question-container">
          <div className="syncin-calendar-wrapper">
            <DatePicker
              minDate={new Date()}
              onChange={this.onChange('launchDate')}
              selected={this.answers.launchDate}
              dateFormat="dd-MM-yyyy"
              placeholderText="Select the launch date of your production"
              className="syncin-calendar"
            />
            <div className="dropdown-arrow">
              <img src={require('assets/images/dropdown-icon.svg')} alt="" />
            </div>
            <div className="divider"></div>
          </div>
          <div className="button-container">
            <BorderlessButton className="button" onClick={prevOnClick}>
              Previous
            </BorderlessButton>
            <Button
              onClick={nextOnClick}
              className="button"
              disabled={!this.valid}>
              Next
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default SynchronizerPageTwo
