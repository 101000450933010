export const filterItems = (values, items) => {
  const itemMap = items.reduce((acc, item) => {
    acc[item.value] = item
    return acc
  }, {})

  return values.reduce((acc, item) => {
    acc.push(itemMap[item])
    return acc
  }, [])
}
