import React from 'react'
import TextInput from 'components/TextInput/index'
import Select from 'components/Select'
import { FILTERED_MEDIA_TYPES } from '../../filter'

export default class MediaType extends React.Component {
  get value() {
    return this.answers.mediaType
  }

  get valueOther() {
    return this.answers.mediaTypeOther || ''
  }

  get onChange() {
    return this.props.onChange
  }

  get answers() {
    return this.props.answers || {}
  }

  get productionType() {
    const productionType = this.answers.productionType || []

    if (Array.isArray(productionType)) {
      return productionType.length ? productionType[0].value : null
    }

    return productionType ? productionType.value : null
  }

  get displayMediaTypeOther() {
    return this.value && this.value.some((item) => item.value === 'other')
  }

  get mediaTypes() {
    return FILTERED_MEDIA_TYPES[this.productionType] || []
  }

  render() {
    return (
      <React.Fragment>
        <Select
          placeholder="Media channels"
          className="question-wrapper"
          disabled={this.productionType ? false : true}
          multiSelect={true}
          options={this.mediaTypes}
          value={this.value}
          onChange={this.onChange('mediaType')}
        />

        {this.displayMediaTypeOther && (
          <div className="otherMediaInput">
            <TextInput
              onChange={this.onChange('mediaTypeOther')}
              placeholder="Describe your type of media channel"
              initialValue={this.valueOther}
              value={this.valueOther}
              wrapperClassName="question-wrapper"
            />
          </div>
        )}
      </React.Fragment>
    )
  }
}
