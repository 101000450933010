export const distributionChannelData = [
  {
    label: 'Facebook',
    value: 'facebook',
  },
  {
    label: 'YouTube',
    value: 'youtube',
  },
  {
    label: 'Other',
    value: 'other',
  },
]

export const TOTAL_PRODUCTION_BUDGET = [
  {
    value: 'high',
    label: 'High',
  },
  {
    value: 'medium',
    label: 'Medium',
  },
  {
    value: 'low',
    label: 'Low',
  },
  {
    value: 'n/a',
    label: 'N/A',
  },
]

export const TOTAL_PRODUCTION_BUDGET_MAP = TOTAL_PRODUCTION_BUDGET.reduce(
  (acc, item) => {
    return acc.set(item.value, item.label)
  },
  new Map()
)

export const DISTRIBUTION_CHANNELS = [
  {
    value: 'Facebook',
    label: 'facebook',
  },
  {
    value: 'Twitter',
    label: 'twitter',
  },
  {
    value: 'YouTube',
    label: 'youtube',
  },
  {
    value: 'Steam',
    label: 'steam',
  },
  {
    value: 'Netflix',
    label: 'netflix',
  },
  {
    value: 'HBO',
    label: 'hbo',
  },
  {
    value: 'Blog',
    label: 'blog',
  },
  {
    value: 'Instagram',
    label: 'instagram',
  },
  {
    value: 'TikTok',
    label: 'tiktok',
  },
  {
    value: 'Email',
    label: 'email',
  },
  {
    value: 'Vimeo',
    label: 'vimeo',
  },
  {
    value: 'Website',
    label: 'website',
  },
  {
    value: 'Podcast',
    label: 'podcast',
  },
  {
    value: 'Webinar',
    label: 'webinar',
  },
  {
    value: 'SnapChat',
    label: 'snapchat',
  },
  {
    value: 'Pinterest',
    label: 'pinterest',
  },
  {
    value: 'LinkedIn',
    label: 'linkedin',
  },
  {
    value: 'ViaPlay',
    label: 'viaplay',
  },
  {
    value: 'DPlay',
    label: 'dplay',
  },
  {
    value: 'TV2 Play',
    label: 'tv2play',
  },
  {
    value: 'DRTV',
    label: 'drtv',
  },
  {
    value: 'TV',
    label: 'tv',
  },
  {
    value: 'Other',
    label: 'other',
  },
]
