import React from 'react'

export const categories = [
  {
    key: 'customer-service',
    title: 'Customer Service',
    image: '/faq/customer_service.svg',
  },
  {
    key: 'general',
    title: 'General',
    image: '/faq/general.svg',
  },
  {
    key: 'guesstimator',
    title: 'Guesstimator',
    image: '/faq/guesstimator.svg',
  },
  {
    key: 'payment',
    title: 'Payment',
    image: '/faq/payment.svg',
  },
  {
    key: 'synchronizer',
    title: 'Synchronizer',
    image: '/faq/synchronizer.svg',
  },
  {
    key: 'terms',
    title: 'Terms and policies',
    image: '/faq/terms.svg',
  },
  {
    key: 'music-owners',
    title: 'Music owners',
    image: '/faq/artist.svg',
  },
]

const faqEntriesBase = [
  {
    category: 'customer-service',
    title: 'Ask a question',
    content: (
      <React.Fragment>
        If you have any questions you can always email us at:{' '}
        <a
          href="mailto:hello@syncin.io"
          target="_blank"
          rel="noopener noreferrer">
          hello@syncin.io
        </a>{' '}
        and we will get back to you asap.
      </React.Fragment>
    ),
  },
  {
    category: 'customer-service',
    title: 'How can I provide feedback on syncin.io?',
    content: (
      <React.Fragment>
        We are constantly improving our service and all feedback form our
        customers is very valuable for us.&nbsp;Please give your feedback at:{' '}
        <a
          href="mailto:hello@syncin.io"
          target="_blank"
          rel="noopener noreferrer">
          hello@syncin.io
        </a>
      </React.Fragment>
    ),
  },
  {
    category: 'customer-service',
    title: 'When can I expect and answer?',
    content: (
      <React.Fragment>
        We will do our best to answer your questions within 24 hours.
      </React.Fragment>
    ),
  },
  {
    category: 'general',
    title: 'What is syncin.io?',
    content: (
      <React.Fragment>
        SyncIn is the first independent digital commercial music licensing
        platform.&nbsp;SyncIn acts as an intermediary between media production
        clients needing commercial music for their story and the music owners.
      </React.Fragment>
    ),
  },
  {
    category: 'general',
    title: 'How do I request a music license?',
    content: (
      <React.Fragment>
        There are three steps in the requesting process.{' '}
        <ol>
          <li>
            {' '}
            First request a price guesstimator through the{' '}
            <a href="/#Guesstimator" target="_blank">
              Guesstimator
            </a>
          </li>{' '}
          <li>You will receive an email with a link for the Synchronizer.</li>{' '}
          <li>
            Share information on the use of music in the Synchronizer tool and
            SyncIn will then contact the music owners and get a price quote for
            you as soon as possible.
          </li>
        </ol>{' '}
        For more information go to{' '}
        <a href="/#HowDoesItWork" target="_blank">
          How does it work
        </a>
      </React.Fragment>
    ),
  },
  {
    category: 'general',
    title: 'What can SyncIn help me with?',
    content: (
      <React.Fragment>
        SyncIn in is a single-point-of-contact for licensing any commercial
        music.&nbsp;As a SyncIn customer, you no longer have to locate the right
        music owners, negotiating prices and terms or handling any music
        licensing contacts or rights.&nbsp;We will seamlessly guide you through
        the whole process and we will only get paid when the license agreement
        is secured.{' '}
      </React.Fragment>
    ),
  },
  {
    category: 'general',
    title: 'Which product should I choose?',
    content: (
      <React.Fragment>
        SyncIn has three different products.
        <ol>
          <li>
            <b>The free guesstimator:</b> that gives you price guesstimates on
            licensing of any commercial music for you production.
          </li>
          <li>
            <b>The synchronizer:</b> where SyncIn gets you a price quote
            directly from the music owners.&nbsp;SyncIn then negotiate and
            secure the license agreement on your request.&nbsp;SyncIn charges a
            fixed service fee for mediating the license agreement.{' '}
          </li>
          <li>
            <b>The customized music supervisors service:</b> where you request
            help from a specialized music supervisor to find the perfect music
            that supports your story within your budget.{' '}
          </li>
        </ol>
        For more information on the pricing of product go to our{' '}
        <a href="/#Pricing" target="_blank">
          pricing site
        </a>
        .
      </React.Fragment>
    ),
  },
  {
    category: 'guesstimator',
    title: 'What is the Guesstimator?',
    content: (
      <React.Fragment>
        The Guesstimator is a free tool that gives you price guesstimates on
        licensing of any commercial music for your production within a single
        day.&nbsp;With a prices guesstimate you can plan and budget commercial
        music into your production.&nbsp;The tool can also be used as a part of
        your creative brainstorming to figure out what music is possible within
        your budget.&nbsp;The Guesstimator gives price intervals that reflect
        what you should expect the music owners to charge for the licensing
        their music. The guesstimate is an educated guess and therefore it can
        differ from a later offer from the music owners.
      </React.Fragment>
    ),
  },
  {
    category: 'guesstimator',
    title: 'What is the Guesstimator based on?',
    content: (
      <React.Fragment>
        The guesstimator calculation is based on a variety of different
        parameters that SyncIn determines the prices of a licensing agreement.
        The calculation is based on machine learning principles which means the
        guesstimates get more accurate with time.
      </React.Fragment>
    ),
  },
  {
    category: 'guesstimator',
    title: 'How accurate is the Guesstimator?',
    content: (
      <React.Fragment>
        The guesstimate is an educated guess and therefore it can differ from a
        later offer from the music owners.&nbsp;Thus the guesstimated intervals
        have been 100% accurate to the asking prices from the music owners
        historically.&nbsp;But SyncIn can not make any guarantees that the
        guesstimate will be 100% accurate in the future.&nbsp;The prices on
        music licensing are variable.&nbsp;The music owners often decided the
        prices on a case-to-case basis, depending on several parameters like:
        the use of the music, the theme of the media production, the brand that
        is involved etc.&nbsp;Therefore there are no guarantees.&nbsp;We try our
        best to keep our guesstimate algorithm in line with market prices.
      </React.Fragment>
    ),
  },
  {
    category: 'payment',
    title: 'How will you be accepting payment?',
    content: (
      <React.Fragment>
        When SyncIn have secured your licenses agreement, you will receives
        invoices from the music owners, paying the licensing fee and an invoice
        from SyncIn, paying with the service fee.
      </React.Fragment>
    ),
  },
  {
    category: 'payment',
    title: 'Can I get a refund?',
    content: (
      <React.Fragment>
        Under normal circumstances there are no refunds made or annulments of
        the licensing agreements.&nbsp;If there are special circumstances where
        one of the two parties does not comply with the terms in the contract,
        the case will be evaluated on a case-by-case basis.&nbsp;SyncIn acts as
        an mediator and the contacts that are formed are legally binding between
        the media productions clients and the music owners.&nbsp;See{' '}
        <a href="/terms" target="_blank" rel="noopener noreferrer">
          Terms of use
        </a>{' '}
        for additional information.
      </React.Fragment>
    ),
  },
  {
    category: 'payment',
    title: 'Can you send me an invoice?',
    content: (
      <React.Fragment>
        You will receive your invoice at the given email address.&nbsp;If you
        have any issues please contact customer service at{' '}
        <a
          href="mailto:hello@syncin.io"
          target="_blank"
          rel="noopener noreferrer">
          hello@syncin.io
        </a>
        .{' '}
      </React.Fragment>
    ),
  },
  {
    category: 'synchronizer',
    title: 'What is the Synchronizer?',
    content: (
      <React.Fragment>
        The synchronizer is a tool you can access from a link in the email with
        your guesstimate.&nbsp;Through the Synchronizer you give SyncIn
        additional information on the use of the music.&nbsp;SyncIn uses the
        information to get an actual price quote directly from the music owners.
        You will receive the price quote on the given email.&nbsp;When you feel
        ready to get the license deal. <br />
        SyncIn will then negotiate the prices and terms with the music owner to
        secure the best license agreement on your behalf.&nbsp;When you inform
        us on your music budget, we use this information to figure out how to
        negotiate for the best possible outcome for you. SyncIn charges a fixed
        service fee for mediating the license agreement.{' '}
      </React.Fragment>
    ),
  },
  {
    category: 'synchronizer',
    title: 'How will SyncIn bargain/negotiate their price quote?',
    content: (
      <React.Fragment>
        When SyncIn delivers a price quote from the music owners in your inbox
        you can either accept the offer or give a counter bid.&nbsp; Accept: If
        you accept the offer you will soon receive a contract on the licensing
        agreement for you to sign and invoices for payment of the SyncIn service
        fee and the licensing of the music. Counter bid: SyncIn will do our best
        to negotiate the best possible outcome.&nbsp; You can also use the
        Synchronizer question on “Additional information”, to tell us what price
        you are willing/can afford to pay for the license.&nbsp;SyncIn will then
        do the best to negotiate within your budget with the music owners.
      </React.Fragment>
    ),
  },
  {
    category: 'terms',
    title: 'Terms of use',
    content: (
      <React.Fragment>
        See{' '}
        <a href="/terms" target="_blank">
          Terms of Use
        </a>{' '}
        for this information.
      </React.Fragment>
    ),
  },
  {
    category: 'terms',
    title: 'Privacy policy',
    content: (
      <React.Fragment>
        See{' '}
        <a href="/privacy" target="_blank">
          Privacy policy
        </a>{' '}
        for this information.
      </React.Fragment>
    ),
  },
  {
    category: 'terms',
    title: 'Cookie policy',
    content: (
      <React.Fragment>
        See{' '}
        <a href="/privacy" target="_blank">
          Cookie policy
        </a>{' '}
        for this information.
      </React.Fragment>
    ),
  },
  {
    category: 'music-owners',
    title: 'Can I sell my music through syncin.io?',
    content: (
      <React.Fragment>
        We always want to partner up with artist and have your information right
        at hand for coming synch requests.&nbsp;But SyncIn are a demand driven
        platform.&nbsp;This means that the media producers are the ones who
        determine what music they want to license and SyncIn will then get in
        contact with the music owners mediating between the two parties.
      </React.Fragment>
    ),
  },
]

// Dynamically add index
const setIndex = (e, i) => {
  e.index = i
  return e
}

export const faqEntries = faqEntriesBase.map((e, i) => setIndex(e, i))
