import * as Sentry from '@sentry/browser'

import BorderlessButton from '../../../components/BorderlessButton/index'
import Button from '../../../components/Button/index'
import React from 'react'
import TextInput from '../../../components/TextInput/index'
import axios from 'axios'
import moment from 'moment'
import { withRouter } from 'react-router-dom'

const apiURL = process.env.REACT_APP_API_URL

class SynchronizerPageFour extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.state = {
      errorMessage: '',
    }
  }

  get answers() {
    return this.props.answers
  }

  get onChange() {
    return this.props.onChange
  }

  handleSubmit() {
    const { onLoad, onSubmit } = this.props
    onLoad()

    const { match } = this.props
    const { params } = match
    const { token } = params
    const { contact = {}, answers = {} } = this.props;
    const {
      launchDate,
      totalProductionBudget,
      storyDescription: description = '',
      distributionChannels = [],
      productionTitle: productionName = '',
      additionalInformation: changesText = '',
      productService: customerProduct,
      advertisementChannelOther: distributor,
      campaignDescriptionAndUse: sceneUsage,
    } = answers

    const budget = totalProductionBudget.value

    let utcOffsetMinute = moment().utcOffset()
    const airDate = launchDate
      ? moment(launchDate).add(utcOffsetMinute, 'minutes').utc()
      : null

    const brandName = answers['companyName']
    const musicBudget = answers['totalMusicBudget']
    const sceneDescription = answers['sceneDescription']

    let durationValue = 0;

    try {
      durationValue = parseInt(answers['durationMinutes']) * 60 + parseInt(answers['durationSeconds'])
    } catch (error) {
      console.log({error});
    }

    const json = {
      description,
      budget,
      distributor,
      productionName,
      airDate,
      brandName,
      musicBudget,
      sceneDescription,
      durationValue,
      changesText,
      customerProduct,
      sceneUsage,
      distributionChannels: distributionChannels.map(({ value }) => value),
      contact: {
        email: contact.email,
        id: contact.id,
        firstName: contact.firstName,
        lastName: contact.lastName
      }
    }

    axios
      .post(`${apiURL}/deals/update/${token}`, json)
      .then(() => {
        onSubmit()
      })
      .catch((e) => {
        this.setState({
          errorMessage: 'An error occured, please try again or contact support',
        })

        if (Sentry) {
          Sentry.captureException(e);
        }
      })
  }

  render() {
    const { prevOnClick, answers, submit } = this.props

    return (
      <div className="SynchronizerPage" id="SynchronizerMarketingPage-4">
        <div className="meta-description">
          <h1>Synchronizer</h1>
        </div>
        <div className="description">Additional information</div>
        <div className="question-container">
          <TextInput
            textType={'textarea'}
            placeholder="State any additional information i.e. Options for later use"
            inputClassName="question-text"
            wrapperClassName="question-wrapper"
            onChange={this.onChange('additionalInformation')}
            initialValue={answers.additionalInformation || ''}
          />

          <div className="button-container">
            <BorderlessButton className="button" onClick={prevOnClick}>
              Previous
            </BorderlessButton>
            <Button onClick={() => submit('marketing')} className="button">
              Submit
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(SynchronizerPageFour)
