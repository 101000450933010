import './Synchronizer.scss'

import * as Sentry from '@sentry/browser'
import * as allMediaPageFour from './AllMediaPages/SynchronizerPageFour'
import * as allMediaPageOne from './AllMediaPages/SynchronizerPageOne'
import * as allMediaPageThree from './AllMediaPages/SynchronizerPageThree'
import * as allMediaPageTwo from './AllMediaPages/SynchronizerPageTwo'
import * as marketingPageFour from './MarketingPages/SynchronizerPageFour'
import * as marketingPageOne from './MarketingPages/SynchronizerPageOne'
import * as marketingPageThree from './MarketingPages/SynchronizerPageThree'
import * as marketingPageTwo from './MarketingPages/SynchronizerPageTwo'

import {
  COUNTRIES_MAP,
  LICENSE_TYPE_MAP,
  MEDIA_TYPE_MAP,
  MUSIC_USE_MAP,
  PRODUCTION_TYPE_MAP,
  REGIONS_MAP,
  TERMS_MAP,
  TERRITORY_SHORT_LIST_MAP
} from '../Guesstimator/data'

import ProgressLine from 'components/ProgressLine/ProgressLine'
import React from 'react'
import { TOTAL_PRODUCTION_BUDGET_MAP } from './AllMediaPages/SynchronizerAllMediaData'
import ThankYou from './DefaultPages/ThankYou'
import WhatWeKnow from './DefaultPages/WhatWeKnow'
import axios from 'axios'
import moment from 'moment'
import { withRouter } from 'react-router-dom'

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const apiURL = process.env.REACT_APP_API_URL

const marketingPages = [
  WhatWeKnow,
  marketingPageOne.default,
  marketingPageTwo.default,
  marketingPageThree.default,
  marketingPageFour.default,
  ThankYou,
]

const allMediaPages = [
  WhatWeKnow,
  allMediaPageOne.default,
  allMediaPageTwo.default,
  allMediaPageThree.default,
  allMediaPageFour.default,
  ThankYou,
]

class Synchronizer extends React.Component {
  constructor(props) {
    super(props)
    this.ProgressLineElement = React.createRef()
    this.incrementSynchronizer = this.incrementSynchronizer.bind(this)
    this.decrementSynchronizer = this.decrementSynchronizer.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
    this.onSubmit = this.onSubmit.bind(this)

    this.SynchronizerRef = React.createRef()

    this.state = {
      currentFragmentIndex: 0,
      loading: true,
      initialAnswers: [],
      errorMessage: '',
      answers: {},
    }
  }

  get loading() {
    return this.state.loading
  }

  get answers() {
    return this.state.answers || {}
  }

  get contact() {
    return this.state.contact || {}
  }

  get errorMessage() {
    return this.state.errorMessage || ''
  }

  componentDidMount() {
    const { match } = this.props
    const { params } = match
    const { token } = params

    axios
      .get(`${apiURL}/deals/get/${token}`)
      .then((response) => {
        const { data = {} } = response || {}

        const { guesstimate = {}, contact = {} } = data || {}

        if (!guesstimate.productionType) {
          this.setState({
            loading: false,
            errorMessage: 'Error loading the data',
          })
          return
        }

        const {
          productionType = {},
          mediaType = [],
          territories = [],
          musicUse = [],
          termType,
          durationValue = {},
          budget,
          licenseType
        } = guesstimate

        guesstimate.additionalInformation = guesstimate.changesText
        guesstimate.productionTitle = guesstimate.productionName
        guesstimate.distributionChannelOther = guesstimate.distributor
        guesstimate.advertisementChannelOther = guesstimate.distributor
        guesstimate.campaignDescriptionAndUse = guesstimate.sceneUsage
        guesstimate.storyDescription = guesstimate.description
        guesstimate.companyName = guesstimate.brandName;
        guesstimate.totalMusicBudget = guesstimate.musicBudget
        guesstimate.productService = guesstimate.customerProduct
        guesstimate.launchDate = guesstimate.airDate
        guesstimate.durationMinutes = durationValue.durationMinutesValue
        guesstimate.durationSeconds = durationValue.durationSecondsValue
        guesstimate.totalProductionBudget = budget
          ? {
              value: budget,
              label: TOTAL_PRODUCTION_BUDGET_MAP.get(budget),
            }
          : undefined

        guesstimate.productionType = productionType ?  {
          value: productionType,
          label: PRODUCTION_TYPE_MAP.get(productionType),
        } : null

        guesstimate.mediaType = mediaType.map((item) => {
          return {
            value: item,
            label: MEDIA_TYPE_MAP.get(item),
          }
        })
        guesstimate.musicUse = musicUse.map((item) => {
          return {
            value: item,
            label: MUSIC_USE_MAP.get(item),
          }
        })
        guesstimate.licenseType = licenseType ?  {
          value: licenseType,
          label: LICENSE_TYPE_MAP.get(licenseType),
        } : null

        guesstimate.termType = termType ?  {
          value: termType,
          label: TERMS_MAP.get(termType),
        } : null 

        guesstimate.territories = territories.map((item) => {
          if (item === 'worldwide') {
            return {
              value: item,
              label: 'Worldwide',
            }
          }

          if (TERRITORY_SHORT_LIST_MAP.get(item)) {
            return {
              value: item,
              label: TERRITORY_SHORT_LIST_MAP.get(item),
            }
          }

          if (REGIONS_MAP.get(item)) {
            return {
              value: item,
              label: REGIONS_MAP.get(item),
            }
          }

          return {
            value: item,
            label: COUNTRIES_MAP.get(item),
          }
        })

        this.setState({
          contact,
          answers: guesstimate,
          loading: false,
          initialAnswers: Object.assign({}, guesstimate),
        })
      })
      .catch((error) => {
        if (Sentry) {
          Sentry.captureException(error);
        }
        const { response, status } = error;
        const { data = {} } = response || {}
        this.setState({
          loading: false,
          errorMessage: data.error || 'An error occured',
          errorStatus: status,
        })
      })
  }

  submit = (type = 'all') => {
    if (type === 'all') {
      this.submitAllMedia();
    } else if (type === 'marketing') {
      this.submitMarketing()
    }
  }

  submitMarketing = () => {
    this.toggleLoading()

    const { answers = {} } = this
    const { match } = this.props
    const { params } = match
    const { token } = params
    const { contact = {} } = this.props;
    const {
      launchDate,
      totalProductionBudget,
      storyDescription: description = '',
      distributionChannels = [],
      productionTitle: productionName = '',
      additionalInformation: changesText = '',
      productService: customerProduct,
      advertisementChannelOther: distributor,
      campaignDescriptionAndUse: sceneUsage,
      extraOptions,
      musicUse = [],
      musicUseOther
    } = answers

    let {
      licenseType = {},
    } = answers

    const budget = totalProductionBudget.value

    let utcOffsetMinute = moment().utcOffset()
    const airDate = launchDate
      ? moment(launchDate).add(utcOffsetMinute, 'minutes').utc()
      : null

    const brandName = answers['companyName']
    const musicBudget = answers['totalMusicBudget']
    const sceneDescription = answers['sceneDescription']

    let durationValue = 0;

    licenseType = licenseType.value ? [licenseType.value] : null

    try {
      durationValue = parseInt(answers['durationMinutes']) * 60 + parseInt(answers['durationSeconds'])
    } catch (error) {
      console.log({error});
    }

    const json = {
      description,
      budget,
      distributor,
      productionName,
      airDate,
      brandName,
      musicBudget,
      sceneDescription,
      durationValue,
      changesText,
      customerProduct,
      sceneUsage,
      extraOptions,
      distributionChannels: distributionChannels.map(({ value }) => value),
      musicUse: musicUse.map(({ value }) => value),
      musicUseOther,
      licenseType,
      contact: {
        email: contact.email,
        id: contact.id,
        firstName: contact.firstName,
        lastName: contact.lastName
      }
    }

    axios
      .post(`${apiURL}/deals/update/${token}`, json)
      .then(() => {
        this.onSubmit()
      })
      .catch((e) => {
        if (Sentry) {
          Sentry.captureException(e);
        }

        this.setState({
          errorMessage: 'An error occured, please try again or contact support',
        })
      })
  }

  submitAllMedia = () => {
    this.toggleLoading()

    const { answers = {} } = this
    const { match } = this.props
    const { params } = match
    const { token } = params
    const {
      launchDate,
      totalProductionBudget,
      storyDescription: description = '',
      distributionChannelOther: distributor = '',
      distributionChannels = [],
      productionTitle: productionName = '',
      additionalInformation: changesText = '',
      extraOptions,
      musicUse = [],
      musicUseOther
    } = answers

    let {
      licenseType = {}
    } = answers

    const budget = totalProductionBudget.value

    let utcOffsetMinute = moment().utcOffset()
    const airDate = launchDate
      ? moment(launchDate).add(utcOffsetMinute, 'minutes').utc()
      : null

    const brandName = answers.companyName
    const musicBudget = answers.totalMusicBudget
    const sceneDescription = answers.sceneDescription
    licenseType = licenseType.value ? [licenseType.value] : null

    let durationValue;

    try {
      durationValue = (parseInt(answers.durationMinutes) || 0) * 60 +
      (parseInt(answers.durationSeconds) || 0)
    } catch (error) {
      console.log({error});
    }

    const json = {
      description,
      budget,
      distributor,
      productionName,
      airDate,
      brandName,
      musicBudget,
      sceneDescription,
      durationValue,
      changesText,
      extraOptions,
      distributionChannels: distributionChannels.map(({ value }) => value),
      musicUse: musicUse.map(({ value }) => value),
      musicUseOther,
      licenseType
    }

    axios
      .post(`${apiURL}/deals/update/${token}`, json)
      .then(() => {
        this.onSubmit()
      })
      .catch((e) => {
        if (Sentry) {
          Sentry.captureException(e);
        }

        this.setState({
          errorMessage: 'An error occured, please try again or contact support',
        })
      })
  }

  incrementSynchronizer() {
    if (this.ProgressLineElement && this.ProgressLineElement.current) {
      this.ProgressLineElement.current.incrementActiveIndex()
    }
    this.setState({
      currentFragmentIndex: this.state.currentFragmentIndex + 1,
    })
    scrollToRef(this.SynchronizerRef)
  }

  decrementSynchronizer() {
    if (this.ProgressLineElement && this.ProgressLineElement.current) {
      this.ProgressLineElement.current.decrementActiveIndex()
    }
    this.setState({
      currentFragmentIndex: this.state.currentFragmentIndex - 1,
    })
    scrollToRef(this.SynchronizerRef)
  }

  toggleLoading() {
    this.setState({
      loading: !this.state.loading,
    })
  }

  onSubmit() {
    this.incrementSynchronizer()
    this.toggleLoading()
  }

  get productionType() {
    return this.state.answers['productionType']
  }

  get fragment() {
    if (!this.productionType || !this.productionType.value) {
      return null
    }

    const fragments =
      this.productionType.value === 'marketing' ? marketingPages : allMediaPages

    if (
      !fragments ||
      !fragments.length ||
      fragments.length < this.currentFragmentIndex
    ) {
      return null
    }

    return fragments[this.currentFragmentIndex]
  }

  get currentFragmentIndex() {
    return this.state.currentFragmentIndex
  }

  onChange = (property) => {
    return (value) => {
      const answers = this.answers

      answers[property] = value

      this.setState({
        answers,
      })
    }
  }

  renderErrorMessage() {
    if (!this.errorMessage) {
      return null
    }

    if (!this.errorMessage.length) {
      return null
    }

    return (
      <div className="error-message">
        We are very sorry, but it seems like we hit a wall while trying to perform
        your request. <br /> The server responded with the following message:
        <i>"{this.errorMessage}"</i> <br />
        If you keep encountering this error, please do not hesitate to contact
        us at{' '}
        <a
          href="mailto:syncin@cleared.dk"
          target="_blank"
          rel="noopener noreferrer">
          syncin@cleared.dk
        </a>
      </div>
    )
  }

  renderContent() {
    if (this.loading || this.errorMessage || this.errorMessage.length) {
      return null
    }

    if (Object.keys(this.state.answers) <= 0) {
      return null
    }

    const Fragment = this.fragment

    if (!Fragment) {
      return null
    }

    return (
      <div>
        {Fragment !== null && (
          <Fragment
            answers={this.answers}
            onProceed={this.incrementSynchronizer}
            prevOnClick={this.decrementSynchronizer}
            nextOnClick={this.incrementSynchronizer}
            onLoad={this.toggleLoading}
            onSubmit={this.onSubmit}
            contact={this.contact}
            onChange={this.onChange}
            submit={this.submit}
          />
        )}

        {this.state.currentFragmentIndex > 0 && (
          <ProgressLine
            ref={this.ProgressLineElement}
            amountOfSteps={5}
            isBlack={true}
            enableHover={false}
            disabled={true}
            currIndex={this.state.currentFragmentIndex - 1}
            progressLineWidth={'100%'}
          />
        )}
      </div>
    )
  }

  render() {
    const loadContent = (
      <div className="loaderWrapper">
        <img src={require('assets/images/syncin-loading-logo.svg')} alt="" />
      </div>
    )

    return (
      <section
        id="Synchronizer"
        className="Synchronizer"
        ref={this.SynchronizerRef}>
        {this.state.loading && loadContent}
        {this.renderErrorMessage()}
        {this.renderContent()}
      </section>
    )
  }
}

export default withRouter(Synchronizer)
