import 'react-datepicker/dist/react-datepicker.css' // css for datepicker

import BorderlessButton from '../../../components/BorderlessButton/index'
import Button from '../../../components/Button/index'
import DatePicker from 'react-datepicker'
import React from 'react'
import TextInput from '../../../components/TextInput/index'

class SynchronizerPageTwo extends React.Component {
  get answers() {
    return this.props.answers
  }

  get onChange() {
    return this.props.onChange
  }

  get valid() {
    const {
      sceneDescription = '',
      durationMinutes,
      durationSeconds,
      launchDate,
    } = this.answers

    return (
      sceneDescription.length > 0 &&
      (durationMinutes || durationSeconds) &&
      launchDate
    )
  }

  render() {
    const { nextOnClick, prevOnClick } = this.props

    return (
      <div className="SynchronizerPage" id="SynchronizerAllMediaPage-2">
        <div className="meta-description">
          <h1>Synchronizer</h1>
        </div>
        <div className="description">Information regarding use</div>
        <div className="question-container">
          <TextInput
            placeholder="Scene description and use of music"
            onChange={this.onChange('sceneDescription')}
            inputClassName="question-text"
            wrapperClassName="question-wrapper"
            initialValue={this.answers.sceneDescription || ''}
          />
          <div className="duration-wrapper question-wrapper">
            <TextInput placeholder="Specify duration:" disabled={true} />
            <TextInput
              placeholder="Minutes"
              textType={'number'}
              onChange={this.onChange('durationMinutes')}
              initialValue={this.answers.durationMinutes || ''}
            />
            <TextInput
              placeholder="Seconds"
              ref={this.durationSeconds}
              textType={'number'}
              onChange={this.onChange('durationSeconds')}
              initialValue={this.answers.durationSeconds || ''}
            />
          </div>

          <div className="syncin-calendar-wrapper">
            <DatePicker
              minDate={new Date()}
              selected={this.answers.launchDate}
              onChange={this.onChange('launchDate')}
              dateFormat="dd-MM-yyyy"
              placeholderText="Select the launch day"
              className="syncin-calendar"
            />
            <div className="divider"></div>
          </div>
          
          <div className="button-container">
            <BorderlessButton className="button" onClick={prevOnClick}>
              Previous
            </BorderlessButton>
            <Button
              onClick={nextOnClick}
              className="button"
              disabled={!this.valid}>
              Next
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default SynchronizerPageTwo
