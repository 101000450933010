import React from 'react'
import './Privacy.scss'

class Privacy extends React.Component {
  render() {
    return (
      <div className="Privacy">
        <div className="text-content">
          <h1>Privacy Notice</h1>

          <div className="text-section">
            <p>Allsync I/S</p>
            <p>Last modified: 15/06-2020</p>
          </div>

          <hr />

          <div className="text-section">
            <p>
              Allsync I/S (SyncIn) is an independent Internet-based advisory
              Platform service for acquiring Music Synchronisation- and master
              use Licenses (“License”) to use recorded musical works (“Music”)
              for media productions. SyncIn is exclusively service for media
              producers (“Music Licensee”) in the process of licensing musical
              rights, from the music rights owners (“Music Licensor”). SyncIn
              advises on all matters, and commits its efforts to provide
              information and negotiate best prices and terms on behalf of the
              Platform Client. SyncIn nether owns, controls, nor manages the
              music rights to which the Services relate, SyncIn is owned by
              SyncIn, a company registered in Denmark under registration no.
              40580751.
            </p>

            <p className="spacing">
              This Privacy Notice applies to your use of the Platform and its
              services including payment services, regardless of how you access
              or use these Services, including access via mobile devices and
              apps.
            </p>

            <p className="spacing">
              Our goal is to be transparent about our business by describing our
              technology and services in simple Notice so that you can
              understand our practices.
            </p>

            <p className="spacing">
              Allsync respects individuals’ rights to privacy and to the
              protection of personal information. The purpose of this Privacy
              Notice is to explain how we collect, use and disclose personal
              information in connection through our platform (the “Platform”).
              “Personal information” means information about a living individual
              who can be identified from that information (either by itself or
              when it is combined with other information).
            </p>
          </div>

          <div className="text-section">
            <h2>Role of SyncIn as a Data Controller or Data Processor</h2>

            <p>
              SyncIn is a “controller” as defined under Danish personal data
              protection law. SyncIn may also act as a “processor” as defined
              under Danish personal data protection to the extent that it
              processes personal data on behalf of and at the instruction of
              somebody else, for example an SyncIn client.
            </p>
          </div>

          <div className="text-section">
            <h2>Categories of information that we collect and use</h2>

            <p>
              When you (an internet user) visit our Platform, we collect certain
              information about your visit. Before log-in we will set cookies on
              your device as regulated by our Cookie Notice.
            </p>
            <p className="spacing">
              When using the guesstimator, we will ask for your name, phone
              number, email address and supplementary information that directly
              identifies you as a person or business.
            </p>

            <p className="spacing">
              To use certain features of the Platform, we may now or in the
              future require you to provide credit card information in order to
              facilitate the processing of payments.
            </p>

            <p className="spacing">
              The Platform is not directed to children under 16 and we do not
              knowingly collect any personal information directly from children
              under 16.
            </p>
          </div>

          <div className="text-section">
            <h2>Purpose of collecting and processing of personal data</h2>

            <p>
              SyncIn uses the information collected on the Platform to provide,
              operate, manage, maintain, and enhance the Platform and allow our
              clients to use our Platform.
            </p>
          </div>

          <div className="text-section">
            <h2>Legal Basis for Processing</h2>

            <p>
              Our processing of your personal data is necessary for the
              performance of the contract to which you are party or in order to
              take steps at your request prior to entering into a contract.
            </p>
            <p className="spacing">
              In other cases we will only process your personal data if you have
              given consent to the processing of data for one or more specific
              purposes.
            </p>
          </div>

          <div className="text-section">
            <h2>Your Rights</h2>

            <p>
              You are entitled to request access to personal data we hold about
              you, and to object to, or request that we restrict, processing of
              your personal data. You may also have the right to ask that your
              personal data be corrected, erased, or transferred to another
              party.
            </p>
          </div>

          <div className="text-section">
            <h2>Retention; Storage; Erasure</h2>

            <p>
              We never store any information longer than we need it. We
              generally retain your personal information for as long as is
              necessary for the performance of the agreement between you and us
              and to comply with our legal obligations.
            </p>

            <p className="spacing">
              We store personal data using generally accepted security standards
              including firewalls and data encryption, and protect the data by
              way of technical and organisational security measures to minimise
              risks associated with data loss, misuse, unauthorized access and
              unauthorized disclosure and alteration.
            </p>

            <p className="spacing">
              Data is usually aggregated or deleted within 60 days. It may,
              however, be retained for up to 6 months, for instance for security
              purposes or for detection and prevention of fraud and invalid
              traffic, or for tax, legal reporting and auditing obligations.
            </p>
            <p className="spacing">
              When we no longer need the information we collect, it is deleted
              or aggregated. However, a residual copy of your personal
              information may not be removed from our backup systems for a
              limited period of time to protect the Platform from loss and
              destruction.
            </p>
          </div>

          <div className="text-section">
            <h2>Disclosure to Comply with Law</h2>

            <p>
              We reserve the right to disclose your information to courts, law
              enforcement, governmental authorities, tax authorities, or
              authorized third parties to the extent we are required or
              permitted to do so by law or if such disclosure is reasonably
              necessary to enforce and administer agreements with you, or to to
              comply with any other of our legal obligations or to comply with a
              valid legal request or to respond to claims asserted against us.
            </p>
          </div>

          <div className="text-section">
            <h2>Privacy Notice Amendments</h2>

            <p>
              Check this page for changes. We may amend the Privacy Notice from
              time to time. Amendments will be effective upon our posting of
              such updated Privacy Notice at this location.
            </p>
            <p className="spacing">
              If you disagree with the revised Privacy Notice, you may cancel
              your Account. Your continued access or use of the Services after
              such posting confirms your consent to be bound by the Privacy
              Notice as amended.
            </p>
            <p className="spacing">
              This Privacy Notice expressly supersede prior agreements or
              arrangements with you.
            </p>
          </div>

          <div className="text-section">
            <h2>Legal Information; Law and Venue</h2>

            <p>
              Allsync I/S is incorporated under the laws of the Kingdom of
              Denmark under Danish Business registration no. 40580751.
            </p>
            <p className="spacing">
              We are a start-up, so we do not have a Data Protection Officer
              appointed.
            </p>
            <p className="spacing">
              If we are not able to resolve your issue, you have the right to
              lodge a complaint with the{' '}
              <a href="https://www.datatilsynet.dk">
                Danish Data Protection Agency (www.datatilsynet.dk).
              </a>
            </p>
            <p className="spacing">
              This Notice is governed by and construed in accordance with Danish
              law without giving effect to any conflict of law principles.
            </p>
            <p className="spacing">
              No venue shall be accepted but the Courts of the City of
              Copenhagen, Denmark,
            </p>
          </div>

          <div className="text-section">
            <h2>Contact Information</h2>

            <p>
              If you have any questions or complaints about this Privacy Notice
              you may email us at :{' '}
              <a href="mailto:syncin@cleared.dk">syncin@cleared.dk</a>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default Privacy
