import React from 'react'
import TextInput from 'components/TextInput/index'
import Select from 'components/Select'
import { PRODUCTION_TYPES } from '../../data'

export default class ProductionType extends React.Component {
  get answers() {
    return this.props.answers || {}
  }

  get value() {
    return this.answers.productionType
  }

  get valueOther() {
    return this.answers.productionTypeOther || ''
  }

  get onChange() {
    return this.props.onChange
  }

  get productionType() {
    return this.value ? this.value.value : null
  }

  render() {
    return (
      <React.Fragment>
        <Select
          onChange={this.onChange('productionType')}
          placeholder={'Type of production'}
          className="question-wrapper"
          initialValue={this.value}
          options={PRODUCTION_TYPES}
          value={this.value}
        />

        {this.productionType === 'other' && (
          <div className="otherProductionInput">
            <TextInput
              onChange={this.onChange('productionTypeOther')}
              value={this.valueOther}
              placeholder="Describe your type of production"
              initialValue={this.valueOther || ''}
              wrapperClassName="question-wrapper"
            />
          </div>
        )}
      </React.Fragment>
    )
  }
}
