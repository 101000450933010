import React from 'react'
import './Testimonials.scss'
import endorsements from './endorsements'
import ProgressLine from 'components/ProgressLine'
import ContactInfo from 'components/ContactInfo/index'
import MediaQuery from 'react-responsive'

class Testimonials extends React.Component {
  constructor() {
    super()
    this.handleOnHover = this.handleOnHover.bind(this)
    this.ProgressLineElement = React.createRef()
    this.state = {
      activeTestimonialIndex: 0,
      activeEndorsement: endorsements[0],
      time: Date.now(),
    }
  }

  componentDidMount() {
    this.interval = setInterval(() => this.incrementTestimonal(), 45000) //change testimonial after 45 secs.
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  handleOnHover(index) {
    this.setState({
      activeTestimonialIndex: index,
      activeEndorsement: endorsements[index],
    })
  }

  incrementTestimonal() {
    this.ProgressLineElement.current.incrementActiveIndex()
    this.setState({
      time: Date.now(),
      activeEndorsement:
        endorsements[
          (this.state.activeTestimonialIndex + 1) % endorsements.length
        ],
      activeTestimonialIndex:
        (this.state.activeTestimonialIndex + 1) % endorsements.length,
    })
  }

  formatTestimonialNumber() {
    return `0${this.state.activeEndorsement.index + 1}.`
  }

  computeMobileLayout(formattedTestimonialNumber) {
    return (
      <section className="Testimonials" id="Testimonials">
        <div className="progressline">
          <ProgressLine
            className="progress-line"
            vertical={true}
            ref={this.ProgressLineElement}
            amountOfSteps={endorsements.length}
            isBlack={true}
            enableHover={true}
            onMouseEnter={this.handleOnHover}
            disabled={false}
            currIndex={0}
            progressLineWidth={'75vh'}
          />
        </div>

        <div>
          <div className="header">
            <h1>
              <b>What our clients say</b>
            </h1>
          </div>

          <div className="content">
            <div className="visuals">
              <p>{formattedTestimonialNumber}</p>
              <div>
                <img src={this.state.activeEndorsement.image} alt="" />
              </div>
            </div>
            <div className="text">
              <img src={require('assets/images/quote.svg')} alt="" />
              <p>{this.state.activeEndorsement.content}</p>
              <p className="testimonials-name">
                {this.state.activeEndorsement.endorser}
              </p>
              <p className="testimonials-job-title">
                {this.state.activeEndorsement.job}
              </p>
            </div>
          </div>
        </div>

        <ContactInfo />
      </section>
    )
  }

  computeDesktopLayout(formattedTestimonialNumber) {
    return (
      <section className="Testimonials" id="testimonials">
        <div className="header">
          <h1>
            <b>What our clients say</b>
          </h1>
        </div>

        <div className="content">
          <div className="visuals">
            <p>{formattedTestimonialNumber}</p>
            <div>
              <img src={this.state.activeEndorsement.image} alt="" />
            </div>
          </div>
          <div className="text">
            <img src={require('assets/images/quote.svg')} alt="" />
            <p>{this.state.activeEndorsement.content}</p>
            <p className="testimonials-name">
              {this.state.activeEndorsement.endorser}
            </p>
            <p className="testimonials-job-title">
              {this.state.activeEndorsement.job}
            </p>
          </div>
        </div>

        <div className="progressline">
          <ProgressLine
            className="progress-line"
            vertical={true}
            ref={this.ProgressLineElement}
            amountOfSteps={endorsements.length}
            isBlack={true}
            enableHover={true}
            onMouseEnter={this.handleOnHover}
            disabled={false}
            currIndex={0}
            progressLineWidth={'100%'}
          />
        </div>
        <ContactInfo />
      </section>
    )
  }

  render() {
    let formattedTestimonialNumber = this.formatTestimonialNumber()
    return (
      <MediaQuery maxDeviceWidth={1024}>
        {(matches) =>
          matches
            ? this.computeMobileLayout(formattedTestimonialNumber)
            : this.computeDesktopLayout(formattedTestimonialNumber)
        }
      </MediaQuery>
    )
  }
}

export default Testimonials
