import './About.scss'

import ContactInfo from 'components/ContactInfo/ContactInfo'
import React from 'react'

class About extends React.Component {
  render() {
    return (
      <section className="About" id="About">
        <div className="about-content">
          <div className="about-image">
            <img src={require('../../assets/images/about-image.jpg')} alt="" />
          </div>

          <div className="about-text">
            <h1>About</h1>
            <div className="about-inner-content">
              <p>
                Licensing pre-existing music it’s complicated, time consuming and expensive. The process is not transparent and bound by legislations that makes all the great music inaccessible. 
              </p>
              <p>
                SyncIn’s mission is to make it accessible and affordable for all types of production. We believe all parties will and can benefit from this. From the media producer - who can create a more powerful outcome for their production. To the music owners and artists being acknowledged and awarded for their work.

              </p>
              <p>
                With this mission and our mutual devotion for music and technology we founded syncin.io back in 2019, funded by Innovation Fund Denmark. 
              </p>

              <p>
                We have incorporated more than 25 years of domain knowledge into a simple music licensing platform where media producers are brought closer to the music owners.
              </p>

              <p>
                <b>Cheers</b>
                <br />
                Anders, Jan, Bo & Katharina, the SyncIn Team
              </p>

              <p>
                Don’t be a stranger - get in touch with us at{' '}
                <a
                  href="mailto:syncin@cleared.dk"
                  target="_blank"
                  rel="noopener noreferrer">
                  syncin@cleared.dk
                </a>
              </p>
            </div>
          </div>
        </div>
        <ContactInfo />
      </section>
    )
  }
}

export default About
