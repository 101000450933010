import React from 'react'
import './Terms.scss'

class Terms extends React.Component {
  render() {
    return (
      <div className="Terms">
        <div className="text-content">
          <h1>Terms of Use</h1>

          <div className="text-section">
            <p>Effective as of 1/06/2020</p>
          </div>

          <hr />

          <div className="text-section">
            <p>
              Allsync I/S (SyncIn) is an independent Internet-based advisory
              Platform service for acquiring Music Synchronisation- and master
              use Licenses (“License”) to use recorded musical works (“Music”)
              for media productions. SyncIn is exclusively service for media
              producers (“Music Licensee”) in the process of licensing musical
              rights, from the music rights owners (“Music Licensor”). SyncIn
              advises on all matters, and commits its efforts to provide
              information and negotiate best prices and terms on behalf of the
              Platform Client. SyncIn nether owns, controls, nor manages the
              music rights to which the Services relate, SyncIn is owned by
              Allsync I/S, a company registered in Denmark under registration
              no. 40580751.
            </p>
            <p className="spacing">
              These Terms of Use (this “User Agreement”) govern the Platform
              Client's access to and use of the Platform and services
              ("Services") made available by SyncIn.
            </p>
            <p className="spacing">
              By accessing or using the Services, the Platform Client agrees to
              the User Agreement.
            </p>
            <p className="spacing">
              The User Agreement constitutes a binding legal agreement between
              the Platform Client and SyncIn and that applies to Platform
              Client’s access of the Site and use of the Services.
            </p>
          </div>

          <div className="text-section">
            <h2>
              <span className="section-number">1.</span> SyncIn's Services
            </h2>

            <p>
              The Services comprise access to the SyncIn Guesstimator, the
              SyncIn Synchronizer and Music Supervision.
            </p>
            <p className="spacing">
              The Guesstimator software provides an estimate of the expected
              price of synchronisation and master use of license to be paid by a
              potential Music Licensee to the Music Licensor. The guesstimate
              does not reflect an actual price quote from the music rights
              owners. The guesstimates are based on assumptions, and are only
              indicative, and cannot under any circumstances express a binding
              offer whatsoever.
            </p>
            <p className="spacing">
              The Synchronizer is a tool for requesting price quotes directly
              from the Music Licensor. SyncIn is not a party to any license
              agreements between Licensor and Platform Client.
            </p>
            <p className="spacing">
              Music Supervision is a personal advisory service where SyncIn’s
              music supervisor can suggest alternative music for the Client’s
              media productions.
            </p>
            <p className="spacing">
              SyncIn’s Services may include a draft contract between Licensor
              and Platform Client.
            </p>
            <p className="spacing">
              SyncIn does not guarantee the existence, possibility, quality,
              safety or legality of the Music License or the ability of sellers
              to sell items or the ability of buyers to pay for items.
            </p>
          </div>

          <div className="text-section">
            <h2>
              <span className="section-number">2.</span> Registration at
              Website; Personal Information
            </h2>
            <p>
              In order to use most aspects of the Services, Platform Client must
              register at the SyncIn website.
            </p>

            <p className="spacing">
              Registration requires Platform Client to submit to SyncIn certain
              personal information, such as Platform Client's name, address,
              mobile phone number, as well as at least one valid payment method
              supported by SyncIn.
            </p>
          </div>

          <div className="text-section">
            <h2>
              <span className="section-number">3.</span> Payment
            </h2>
            <p>
              The Use of the Services results in charges to be paid by Platform
              Client to SyncIn for the services Platform Client receive
              (“Successful License Transfer Fees").
            </p>

            <p className="spacing">
              Succesful License Transfer Fees become due when Licensor’s
              identity information is delivered to Platform Client, when
              Platform Client and Licensor engage into commercial dialogue, or,
              at the latest when the Platform Client acquires the Music License
              from the Music Licensor.
            </p>
            <p className="spacing">
              Succesful License Transfer Fees will be inclusive of applicable
              taxes where required by law. Succesful License Transfer Fees may
              include other applicable fees.
            </p>

            <p className="spacing">
              Platform Client will receive an invoice by e-mail. SyncIn may
              introduce payment gateway or other electronic payment solution for
              invoicing purposes.
            </p>

            <p className="spacing">
              SyncIn reserves the right to establish, remove and/or revise
              Succesful License Transfer Fees for any or all services at any
              time in SyncIn's sole discretion by posting the changes on the
              Platform site 14 days in advance, but with no advance notice
              required for temporary promotions or any changes that result in
              the reduction of Succesful License Transfer Fees.
            </p>
          </div>

          <div className="text-section">
            <h2>
              <span className="section-number">4.</span> Limitation of
              Liability; Exclusion of Warranties; Indemnity
            </h2>

            <p>
              Although SyncIn tries to keep the Services functioning as intended
              by SyncIn, SyncIn cannot guarantee the continuous operation of or
              access to SyncIn's Services.
            </p>

            <p className="spacing">
              The Guesstimator is solely informational. SyncIn is not
              responsible in the event that a price quote from the Music
              Licensor might differ from the calculated or finally agreed price.
            </p>

            <p className="spacing">
              SyncIn excludes all express or implied warranties, User Agreement
              and conditions including, but not limited to, implied warranties
              of merchantability, fitness for a particular purpose, and
              non-infringement.
            </p>
            <p className="spacing">
              The liability of the Parties shall be governed by Danish law.
            </p>

            <p className="spacing">
              To the maximum extent permitted by law, in no event shall either
              party to this User Agreement or its employees, officers,
              representatives and directors be liable to the other party to this
              User Agreement or its employees, officers, representatives and
              directors for any loss of profits, management time, savings,
              contracts, revenue, invest, goodwill, data, or for any penalties,
              fines, or for any consequential, special, indirect, or exemplary
              damages, costs, expenses, or losses (including, without
              limitation, for negligence).
            </p>

            <p className="spacing">
              However, in no circumstance shall SyncIn become liable for more
              than the paid Commission Charge.
            </p>

            <p className="spacing">
              Platform Client agrees to indemnify SyncIn and its directors,
              employees, and agents and affilates harmless from any and all
              claims, demands, losses, liabilities, and expenses (including
              attorneys' fees), arising out of or in connection with Platform
              Client's use of the Services or Platform Client's breach or
              violation of any of this User Agreement, of any law or any rights
              of a third party.
            </p>
          </div>

          <div className="text-section">
            <h2>
              <span className="section-number">5.</span> Termination and
              Cessation of Services
            </h2>

            <p>
              SyncIn may immediately terminate this User Agreement or any
              Services with respect to Platform Client. SyncIn may generally
              cease offering or deny access to the Services or any portion
              thereof at any time for any reason.
            </p>
          </div>

          <div className="text-section">
            <h2>
              <span className="section-number">6.</span> Amendment of User
              Agreement
            </h2>

            <p className="spacing">
              SyncIn may amend the User Agreement from time to time. Amendments
              will be effective upon SyncIn's posting of such updated User
              Agreement at this location or in the amended policies or
              supplemental terms of the applicable Service(s).
            </p>

            <p className="spacing">
              Platform Client's continued access or use of the Services after
              such posting confirms Platform Client's consent to be bound by the
              User Agreement, as amended.
            </p>

            <p className="spacing">
              If SyncIn changes this User Agreement after the date Platform
              Client first agreed to the User Agreement (or to any subsequent
              changes to this User Agreement), Platform Client may reject any
              such change by providing SyncIn written notice of such rejection
              (“Notice of Rejection”) within 30 days of the date such change
              became effective, as indicated in the "Effective" date above. The
              Notice of Rejection must be provided by email from the email
              address associated with Platform Client's Account to:
              syncin@cleared.dk In order to be effective, the Notice of Rejection
              must include Platform Client's full name and other identification
              details, including company identification details, and clearly
              indicate Platform Client's intent to reject changes to this User
              Agreement. By rejecting changes, Platform Client are agreeing that
              Platform Client will continue to be bound by the provisions of
              this User Agreement as of the date Platform Client first agreed to
              the User Agreement or to any later changes to this User Agreement.
            </p>
          </div>

          <div className="text-section">
            <h2>
              <span className="section-number">7.</span> Termination and
              Cessation of Services
            </h2>

            <p>
              Platform Client may not assign this User Agreement without
              SyncIn's prior written approval.
            </p>

            <p className="spacing">
              Without Platform Client's consent SyncIn may assign this User
              Agreement to a subsidiary or affiliate, an acquirer of SyncIn's
              equity, business or assets, or a successor by merger or de-merger.
              Any purported assignment in violation of this section shall be
              void.
            </p>
          </div>

          <div className="text-section">
            <h2>
              <span className="section-number">8.</span> Communication for Legal
              Purposes; Effect; Other Clauses for Legal Purposes
            </h2>

            <p>
              All notices must be in writing and addressed to the relevant party
              at the address as provided pursuant to this User Agreement.
            </p>

            <p className="spacing">
              Each party must take all steps as may be reasonably required by
              the other party to give effect to this User Agreement.
            </p>

            <p className="spacing">
              This User Agreement set forth the entire understanding and
              agreement between Platform Client and SyncIn with respect to its
              subject matter, and expressly supersede all prior understandings
              and agreements or arrangements of the parties.
            </p>
            <p className="spacing">
              Each party acknowledges that in entering into this User Agreement
              it has not relied on any representation or warranties about its
              subject matter except as provided in this User Agreement.
            </p>

            <p className="spacing">
              No delay, neglect or forbearance on the part of any party in
              enforcing against any other party any obligation under this User
              Agreement will operate as a waiver or in any way prejudice any
              right under this User Agreement.
            </p>

            <p className="spacing">
              If any provision of this User Agreement is held to be
              unenforceable for any reason, such provision shall be struck.
              Remaining provisions shall be enforced to the fullest extent
              allowed under applicable law.
            </p>
          </div>

          <div className="text-section">
            <h2>
              <span className="section-number">9.</span> Legal Information; Law
              and Venue
            </h2>

            <p>
              Allsync I/S is incorporated under the laws of the Kingdom of
              Denmark under Danish Business registration no. 40580751.
            </p>
            <p className="spacing">
              This User Agreement are governed by and construed in accordance
              with Danish law without giving effect to any conflict of law
              principles.
            </p>
            <p className="spacing">
              No venue shall be accepted but the Courts of the City of
              Copenhagen, Denmark.
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default Terms
