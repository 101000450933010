import React from 'react'
import { slide as Menu } from 'react-burger-menu'
import './Navigation.scss'
import ContactInfo from 'components/ContactInfo'
import { Link } from 'react-router-dom'

const styles = {
  bmCross: {
    width: 3,
    height: 20,
  },
  bmCrossButton: {
    width: 24,
    height: 24,
    top: 34,
    right: '4%',
  },
  // overflow hidden solves horizontal scroll issue:
  // https://github.com/negomi/react-burger-menu/wiki/FAQ
  bmMenu: {
    overflow: 'hidden',
  },
}

class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
    }
  }

  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen })
  }

  closeMenu() {
    this.setState({ menuOpen: false })
  }

  toggleMenu() {
    this.setState((state) => ({ menuOpen: !state.menuOpen }))
  }

  handleNavigate(activateCallback) {
    const { onNavigate } = this.props

    this.closeMenu()
    if (activateCallback && onNavigate) {
      onNavigate()
    }
  }

  render() {
    return (
      <Menu
        burgerButtonClassName={'customBurgerButton'}
        overlayClassName={'NavigationOverlay'}
        className="Navigation"
        right
        width={'100%'}
        styles={styles}
        disableAutoFocus={true}
        customBurgerIcon={<img src="/burger-menu.svg" alt="" />}
        isOpen={this.state.menuOpen}
        onStateChange={(state) => this.handleStateChange(state)}>
        <div className="menu-item-container">
          <div>
            <a
              className="menu-item"
              href="/#Guesstimator"
              onClick={() => this.handleNavigate()}>
              Guesstimator
            </a>

            <a
              className="menu-item"
              href="/#About"
              onClick={() => this.handleNavigate()}>
              About
            </a>

            <a
              className="menu-item"
              href="/#Testimonials"
              onClick={() => this.handleNavigate()}>
              Clients
            </a>

            <a
              className="menu-item"
              href="/#HowDoesItWork"
              onClick={() => this.handleNavigate()}>
              Licensing
            </a>

            <a
              className="menu-item"
              href="/#Pricing"
              onClick={() => this.handleNavigate()}>
              Pricing
            </a>
          </div>
          <ContactInfo />
        </div>

        <div className="menuFooter">
          <a
            className="menu-footer-item"
            href="/#FAQ"
            onClick={() => this.handleNavigate()}>
            FAQ
          </a>

          <Link
            to="/terms"
            target="_blank"
            className="menu-footer-item"
            onClick={() => this.handleNavigate(false)}>
            Terms of Use
          </Link>
          <Link
            to="/privacy"
            target="_blank"
            className="menu-footer-item"
            onClick={() => this.handleNavigate(false)}>
            Privacy Policy
          </Link>
        </div>
      </Menu>
    )
  }
}

export default Navigation
