import Button from 'components/Button/Button'
import React from 'react'
import TextInput from 'components/TextInput/index'

class GuesstimatorPageOne extends React.Component {
  get answers() {
    return this.props.answers || {}
  }

  get onChange() {
    return this.props.onChange
  }

  get song() {
    return this.answers.song || ''
  }

  get artist() {
    return this.answers.artist || ''
  }

  get valid() {
    return this.artist.length > 0 && this.song.length > 0
  }

  render() {
    const { nextOnClick } = this.props

    return (
      <div className="GuesstimatorPage" id="GuesstimatorPage-1">
        <div className="meta-description">
          What does it cost to license music for your production? Try the <a href="/#faq-guesstimator">Guesstimator</a> for free and find out today.

          The <a href="/#faq-guesstimator">Guesstimator</a> calculates what price you can expect the music owners to charge for the use. It's based on historical data and other price dependent parameters. 
        </div>
        <div className="description">Start here</div>
        <div className="question-container">
          <TextInput
            placeholder="Name of the artist"
            onChange={this.onChange('artist')}
            inputClassName="question-text"
            wrapperClassName="question-wrapper"
            initialValue={this.artist}
            value={this.artist}
          />
          <TextInput
            placeholder="Name of the song"
            onChange={this.onChange('song')}
            inputClassName="question-text"
            wrapperClassName="question-wrapper"
            initialValue={this.song}
            value={this.song}
          />

          <div className="button-container">
            <a href="/#Guesstimator">
              <Button
                onClick={nextOnClick}
                disabled={!this.valid}
                className="next-button">
                Next
              </Button>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default GuesstimatorPageOne
