import BorderlessButton from '../../../components/BorderlessButton/index'
import Button from '../../../components/Button/index'
import LicenseType from '../components/LicenseType'
import MusicUse from '../components/MusicUse'
import React from 'react'
import TextInput from '../../../components/TextInput/index'

class SynchronizerPageOne extends React.Component {
  get answers() {
    return this.props.answers
  }

  get onChange() {
    return this.props.onChange
  }

  get valid() {
    const {
      companyName = '',
      productionTitle = '',
      storyDescription = '',
      distributionChannelOther = '',
    } = this.answers

    return (
      companyName.length > 0 &&
      productionTitle.length > 0 &&
      storyDescription.length > 0 &&
      distributionChannelOther.length > 0
    )
  }

  render() {
    const { nextOnClick, prevOnClick } = this.props

    return (
      <div className="SynchronizerPage" id="SynchronizerAllMediaPage-1">
        <div className="meta-description">
          <h1>Synchronizer</h1>
        </div>
        <div className="description">Information regarding your production</div>
        <div className="question-container">
          <TextInput
            placeholder="Name the client/distributor"
            onChange={this.onChange('companyName')}
            inputClassName="question-text"
            wrapperClassName="question-wrapper"
            initialValue={this.answers.companyName || ''}
          />
          <TextInput
            placeholder="Production title"
            onChange={this.onChange('productionTitle')}
            inputClassName="question-text"
            wrapperClassName="question-wrapper"
            initialValue={this.answers.productionTitle || ''}
          />
          <TextInput
            placeholder="Specify distribution channels (e.g. Facebook, Youtube)"
            inputClassName="question-text"
            initialValue={this.answers.distributionChannelOther || ''}
            wrapperClassName="question-wrapper"
            onChange={this.onChange('distributionChannelOther')}
          />

          <TextInput
            textType={'textarea'}
            placeholder="Story description"
            onChange={this.onChange('storyDescription')}
            inputClassName="question-text"
            wrapperClassName="question-wrapper"
            initialValue={this.answers.storyDescription || ''}
          />

          <LicenseType 
            answers={this.answers}
            placeholder="Version"
            onChange={this.onChange}
          />

          <MusicUse 
            answers={this.answers}
            onChange={this.onChange}
          />

          <div className="button-container">
            <BorderlessButton className="button" onClick={prevOnClick}>
              Previous
            </BorderlessButton>
            <Button
              onClick={nextOnClick}
              className="button"
              disabled={!this.valid}>
              Next
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default SynchronizerPageOne
