import './Pricing.scss'

import Button from 'components/Button/Button'
import ContactInfo from 'components/ContactInfo/index'
import MediaQuery from 'react-responsive'
import React from 'react'

class Pricing extends React.Component {
  handleCustom() {
    window.open('mailto:syncin@cleared.dk@')
  }

  handleGuesstimatorClick() {
    window.open('/#Guesstimator')
  }

  renderDesktopLayout() {
    return (
      <section className="pricing-section" id="Pricing">
        <div className="pricing-header">
          <h1>Pricing</h1>
        </div>
        <div className="pricing">
          <div className="pricing-elements">
            <div className="element divider-element">
              <div className="element-content">
                <div className="price-title">
                  <img
                    src={require('assets/images/pricing/guesstimator.svg')}
                    alt=""
                  />
                  <h2>Guesstimator</h2>
                </div>
                <div className="price-desc">
                  <p>
                    Access the free Guesstimator tool and receive an estimate on the licensing of music.
                  </p>
                </div>

                <br className="spacing-fix-break-first" />

                <div className="price-list">
                  <p>Free</p>
                  <p>Price calculation</p>
                </div>

                <div className="price-btn">
                  <Button
                    className="pricing-button"
                    onClick={() => this.handleGuesstimatorClick()}>
                    Free
                  </Button>
                </div>
              </div>
            </div>

            <div className="element divider-element">
              <div className="element-content">
                <div className="price-title">
                  <img
                    src={require('assets/images/pricing/synchronizer.svg')}
                    alt=""
                  />
                  <h2>Syncronizer</h2>
                </div>
                <div className="price-desc">
                  <p>
                    Get an offer from the music owners with the synchronizer tool.
                  </p>
                </div>

                <br className="spacing-fix-break spacing-fix-break-second" />

                <div className="price-list">
                  <p>Research</p>
                  <p>Client mediation</p>
                </div>

                <div className="price-btn">
                  <Button
                    className="pricing-button"
                    onClick={() => this.handleGuesstimatorClick()}>
                    1.250 DKK*
                  </Button>
                </div>
              </div>
            </div>
            
            <div className="element divider-element">
              <div className="element-content">
                <div className="price-title">
                  <img
                    src={require('assets/images/pricing/licensing.svg')}
                    alt=""
                  />
                  <h2>Licensing Service</h2>
                </div>
                <div className="price-desc">
                  <p>
                    Obtain rights from music owners to license their track through SyncIn.
                  </p>
                </div>

                <br className="spacing-fix-break spacing-fix-break-third" />

                <div className="price-list">
                  <p>Negotiation</p>
                  <p>Licensing Agreement</p>
                </div>

                <div className="price-btn">
                  <Button
                    className="pricing-button"
                    onClick={() => this.handleGuesstimatorClick()}>
                    4.500 DKK*
                  </Button>
                </div>
              </div>
            </div>

            <div className="element">
              <div className="element-content">
                <div className="price-title">
                  <img src={require('assets/images/pricing/agent.svg')} alt="" />
                  <h2>Music Supervision</h2>
                </div>
                <div className="price-desc">
                  <p>
                    Professional  recommendation aimed at your specific project.
                  </p>
                </div>

                <br className="spacing-fix-break" />
                <br className="spacing-fix-break" />

                <div className="price-list">
                  <p>Music Consulting</p>
                  <p>Receive three proposals</p>
                </div>

                <div className="price-btn">
                  <Button
                    className="pricing-button"
                    onClick={(e) => this.handleCustom()}>
                    Custom
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="break"></div>

          <div className="notice-elements">
            <div className="notice-element divider-element">
              <p className="vat-notice">
                All prices are excl. VAT
              </p>
            </div>
            <div className="notice-element divider-element"></div>
            <div className="notice-element divider-element">
              <p className="price-notice">
                *&nbsp;Syncin will charge a total of 7,5% when the licensing price exceeds 75.000 DKK 
              </p>
            </div>
            <div className="notice-element"></div>
          </div>
        </div>

        <ContactInfo />
      </section>
    )
  }

  renderTabletLayout() {
    return (
      <section className="pricing-section" id="pricing">
        <div className="tablet-pricing-header">
          <h1>Pricing</h1>
        </div>
        <div className="tablet-pricing">
          <div className="tablet-pricing-elements">
            <div className="tablet-element">
              <div className="tablet-price-title">
                <img
                  src={require('assets/images/pricing/guesstimator.svg')}
                  alt=""
                />
                <h2>Guesstimator</h2>
              </div>
              <div className="tablet-price-desc">
                <p>
                  Access the free Guesstimator tool and receive an estimate on the licensing of music.
                </p>
              </div>
              <div className="tablet-price-btn">
                <Button
                  className="tablet-pricing-button"
                  onClick={() => this.handleGuesstimatorClick()}>
                  Free
                </Button>
              </div>
            </div>

            <div className="tablet-element">
              <div className="tablet-price-title">
              <img
                  src={require('assets/images/pricing/synchronizer.svg')}
                  alt=""
                />
                <h2>Syncronizer</h2>
              </div>
              <div className="tablet-price-desc">
                <p>
                  Get an offer from the music owners with the synchronizer tool.
                </p>
              </div>
              <div className="tablet-price-btn">
                <Button
                  className="tablet-pricing-button"
                  onClick={() => this.handleGuesstimatorClick()}>
                  1.250 DKK*
                </Button>
              </div>
            </div>

            <div className="tablet-element">
              <div className="tablet-price-title">
                <img
                  src={require('assets/images/pricing/licensing.svg')}
                  alt=""
                />
                <h2>Licensing Service</h2>
              </div>
              <div className="tablet-price-desc">
                <p>
                  Obtain rights from copyright owners to license their number
                  through syncin.io.
                </p>
              </div>
              <div className="tablet-price-btn">
                <Button
                  className="tablet-pricing-button"
                  onClick={() => this.handleGuesstimatorClick()}>
                  4.500 DKK*
                </Button>
              </div>

              <div className="asterisk">
                <p>
                  * Fixed SyncIn service fee for mediating licensing agreement
                  (fee excl. VAT).
                </p>
              </div>
            </div>

            <div className="tablet-element">
              <div className="tablet-price-title">
                <img src={require('assets/images/pricing/agent.svg')} alt="" />
                <h2>Agent</h2>
              </div>
              <div className="tablet-price-desc">
                <p>
                  Professional and personal recommendation aimed at your
                  specific project.
                </p>
              </div>
              <div className="tablet-price-btn">
                <Button
                  className="tablet-pricing-button"
                  onClick={(e) => this.handleCustom()}>
                  Custom
                </Button>
              </div>

              <div className="asterisk">
                <p>
                  All prices are excl. VAT
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

  render() {
    return (
      <div className="Pricing">
        <MediaQuery maxDeviceWidth={1024}>
          {(matches) =>
            matches ? this.renderTabletLayout() : this.renderDesktopLayout()
          }
        </MediaQuery>
      </div>
    )
  }
}

export default Pricing
