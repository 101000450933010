import React from 'react'
import './BorderlessButton.scss'
import classNames from 'classnames'

class BorderlessButton extends React.Component {
  render() {
    const { children, className, active, onClick, disabled } = this.props

    return (
      <div
        className={classNames('BorderlessButton drawer-button', className, {
          active,
          disabled,
        })}
        onClick={() => (!disabled ? onClick() : null)}>
        {children}
      </div>
    )
  }
}

export default BorderlessButton
