import './Guesstimator.scss'

import ContactInfo from 'components/ContactInfo/index'
import GuesstimatorPageOne from './Pages/GuesstimatorPageOne'
import GuesstimatorPageThree from './Pages/GuesstimatorPageThree'
import GuesstimatorPageTwo from './Pages/GuesstimatorPageTwo'
import Offer from './Offer'
import ProgressLine from 'components/ProgressLine/ProgressLine'
import React from 'react'
import ReactTooltip from 'react-tooltip'
import ThankYou from './ThankYou'

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

class Guesstimator extends React.Component {
  constructor(props) {
    super(props)
    this.incrementGuesstimator = this.incrementGuesstimator.bind(this)
    this.decrementGuesstimator = this.decrementGuesstimator.bind(this)
    this.addAnswer = this.addAnswer.bind(this)
    this.submitGuesstimate = this.submitGuesstimate.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
    this.restart = this.restart.bind(this)

    this.ProgressLineElement = React.createRef()
    this.GuesstimatorRef = React.createRef()
    this.state = {
      currentFragmentIndex: 0,
      answers: {
      },
      loading: false,
      submitted: false,
      guesstimate: {}
    }
  }

  get guesstimate() {
    return this.state.guesstimate
  }

  get answers() {
    return this.state.answers || {}
  }

  onChange = (property) => {
    return (value) => {
      const answers = this.answers

      // eslint-disable-next-line default-case
      switch (property) {
        case 'productionType': {
          answers['mediaType'] = null
          answers['mediaTypeOther'] = null
          answers['productionTypeOther'] = null
          answers['termType'] = null
          answers['territoryType'] = null
          answers['termDuration'] = null
          break
        }

        case 'mediaType': {
          answers['termType'] = null
          answers['territoryType'] = null
          answers['termDuration'] = null
          break
        }

        case 'termType': {
          answers['termType'] = null
          answers['termDuration'] = null
          break
        }
      }

      answers[property] = value

      this.setState({
        answers,
      })
    }
  }

  submitGuesstimate(result = {}) {
    this.ProgressLineElement.current.incrementActiveIndex()
    this.setState({
      currentFragmentIndex: 3,
      submitted: true,
      loading: false,
      guesstimate: result.guesstimate
    })
  }

  restart() {
    this.ProgressLineElement.current.setActiveIndex(0, 4)
    this.setState({
      currentFragmentIndex: 0,
      answers: {},
      submitted: false,
      loading: false,
    })
  }

  get currentFragmentIndex() {
    return this.state.currentFragmentIndex
  }

  addAnswer(name, value) {
    this.setState({
      answers: Object.assign(this.state.answers, { [name]: value }),
    })
  }

  incrementGuesstimator() {
    this.ProgressLineElement.current.incrementActiveIndex()
    this.setState({
      currentFragmentIndex: this.state.currentFragmentIndex + 1,
    })
    scrollToRef(this.GuesstimatorRef)
  }

  decrementGuesstimator() {
    this.ProgressLineElement.current.decrementActiveIndex()
    this.setState({
      currentFragmentIndex: this.state.currentFragmentIndex - 1,
    })
    scrollToRef(this.GuesstimatorRef)
  }

  toggleLoading() {
    this.setState({
      loading: !this.state.loading,
    })
  }

  get fragments() {
    let showGuesstimate = false;

    if (this.guesstimate && this.guesstimate.price && this.guesstimate.price.low && this.guesstimate.dealLink) {
      showGuesstimate = true;
    }

    return [
      GuesstimatorPageOne,
      GuesstimatorPageTwo,
      GuesstimatorPageThree,
      showGuesstimate ? Offer : ThankYou,
    ]
  }

  get fragment() {
    return this.fragments[this.currentFragmentIndex]
  }

  render() {
    const Fragment = this.fragment

    const explanationText = `
      The guesstimate reflects what you can expect a music owners will charge for licensing of their music. It's an educated guess and shall not be perceived as an actual offer.  Be aware that the guesstimates can differ from a later offer from the music owners. The guesstimates are based on a combination of historical prices from 25 years and a variety of price-dependent variables - determined by SyncIn. `

    const content = (
      <div>
        <div
          className="headline"
          style={{ display: this.state.submitted ? 'none' : 'block' }}>
          <h1>
            <b>Guesstimator</b>
            <div
              data-multiline={true}
              data-place="bottom"
              data-for="explanation"
              style={{ cursor: 'pointer' }}
              data-tip={explanationText}
              ref={(ref) => (this.explanationRef = ref)}
              className="tooltip-button">
              ?
            </div>
            <ReactTooltip id="explanation" className="tooltip" />
          </h1>
        </div>

        <Fragment
          nextOnClick={this.incrementGuesstimator}
          prevOnClick={this.decrementGuesstimator}
          addAnswer={this.addAnswer}
          onChange={this.onChange}
          answers={this.state.answers}
          toggleLoading={this.toggleLoading}
          submit={this.submitGuesstimate}
          restart={this.restart}
          onLoad={this.toggleLoading}
          onSubmit={this.submitGuesstimate}
          guesstimate={this.guesstimate}
        />
      </div>
    )

    const loading = (
      <div className="loaderWrapper">
        <img src={require('assets/images/syncin-loading-logo.svg')} alt="" />
      </div>
    )

    return (
      <section
        id="Guesstimator"
        className="Guesstimator"
        ref={this.GuesstimatorRef}>
        {this.state.loading && loading}

        <div style={{ display: this.state.loading ? 'none' : 'block' }}>
          {content}
        </div>

        <div style={{ display: this.state.loading ? 'none' : 'block' }}>
          <ProgressLine
            ref={this.ProgressLineElement}
            amountOfSteps={4}
            isBlack={true}
            enableHover={false}
            disabled={true}
            currIndex={0}
            progressLineWidth={'100%'}
          />
        </div>
        <ContactInfo />
      </section>
    )
  }
}

export default Guesstimator
