import './FAQ.scss'
import 'semantic-ui-css/semantic.min.css'

import { categories, faqEntries } from './FaqEntries'

import { Accordion } from 'semantic-ui-react'
import ContactInfo from '../../components/ContactInfo/index'
import React from 'react'

class FAQ extends React.Component {
  state = { activeIndex: -1 }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index
    this.setState({ activeIndex: newIndex })
  }

  generateAccordionForCategory(category) {
    const entries = faqEntries.filter((e) => e.category === category.key)
    const panels = entries.map((e) => {
      return {
        key: e.index,
        title: e.title,
        content: {
          content: <div className="accordionContent">{e.content}</div>,
        },
      }
    })
    return (
      <div key={category.key}>
        <div className="categoryHeading">
          <img src={category.image} alt="" />
          <h1 id={`faq-${category.key}`}>{category.title}</h1>
        </div>
        <Accordion
          panels={panels}
          onTitleClick={this.handleClick}
          className="categoryAccordion"
        />
      </div>
    )
  }

  generateAllFAQEntries() {
    return categories.map((category) => {
      return (
        <div className="entry" key={category.key}>
          {this.generateAccordionForCategory(category)}
        </div>
      )
    })
  }

  render() {
    return (
      <section className="FAQ" id="FAQ">
        <div className="FAQ-headline">
          <h1>
            <b>Frequently Asked Questions</b>
          </h1>
        </div>

        <div className="items">{this.generateAllFAQEntries()}</div>
        <ContactInfo />
      </section>
    )
  }
}

export default FAQ
