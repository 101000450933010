import React from 'react'
import './ProgressLine.scss'
import classNames from 'classnames'
import 'react-step-progress-bar/styles.css'
import { ProgressBar, Step } from 'react-step-progress-bar'

class ProgressLine extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeIndex: props.currIndex === 'undefined' ? 0 : props.currIndex,
      calculatedPercent:
        props.currIndex === 'undefined'
          ? 0
          : this.calculatePercent(props.currIndex, props.amountOfSteps),
    }
  }

  calculatePercent(index, amountOfSteps) {
    const percentageOfEachStep = 100 / (amountOfSteps - 1)
    return index * percentageOfEachStep
  }

  setActiveIndex(index, amountOfSteps) {
    const calculatedPercentage = this.calculatePercent(index, amountOfSteps)
    this.setState({
      activeIndex: index,
      calculatedPercent: calculatedPercentage,
    })
  }

  decrementActiveIndex() {
    const calculatedPercentage = this.calculatePercent(
      (this.state.activeIndex - 1) % this.props.amountOfSteps,
      this.props.amountOfSteps
    )
    this.setState({
      activeIndex: (this.state.activeIndex - 1) % this.props.amountOfSteps,
      calculatedPercent: calculatedPercentage,
    })
  }

  incrementActiveIndex() {
    const calculatedPercentage = this.calculatePercent(
      (this.state.activeIndex + 1) % this.props.amountOfSteps,
      this.props.amountOfSteps
    )
    this.setState({
      activeIndex: (this.state.activeIndex + 1) % this.props.amountOfSteps,
      calculatedPercent: calculatedPercentage,
    })
  }

  handleOnMouseEnter(index, amountOfSteps, enableHover, onMouseEnter) {
    if (!enableHover) {
      return
    }

    if (!onMouseEnter) {
      this.setActiveIndex(index, amountOfSteps)
      return
    }

    onMouseEnter(index)
    this.setActiveIndex(index, amountOfSteps)
  }

  handleTiming(index, amountOfSteps, timingFunction) {
    timingFunction()
    this.setActiveIndex(index, amountOfSteps)
  }

  itemOnClick = (index) => {
    return () => {
      const { amountOfSteps, disabled } = this.props
      if (disabled) {
        return false
      }

      this.setActiveIndex(index, amountOfSteps)
    }
  }

  render() {
    const {
      className,
      progressLineWidth,
      dotSize,
      progressLineHeight,
      amountOfSteps,
      enableHover,
      onMouseEnter,
      isBlack,
    } = this.props

    return (
      <div className={classNames('ProgressLine', className)}>
        <ProgressBar
          filledBackground="#FFCC66"
          width={progressLineWidth || 100}
          height={progressLineHeight || 3}
          percent={this.state.calculatedPercent}>
          {Array.from(Array(amountOfSteps), (step, index) => {
            return (
              <Step transition="scale" key={index}>
                {({ accomplished, index }) => (
                  <img
                    onMouseEnter={() => {
                      this.handleOnMouseEnter(
                        index,
                        amountOfSteps,
                        enableHover,
                        onMouseEnter
                      )
                    }}
                    onClick={this.itemOnClick(index)}
                    style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                    width={dotSize || '30'}
                    src={
                      isBlack
                        ? require('assets/images/dot-black-progressline.svg')
                        : require('assets/images/dot-white-progressline.svg')
                    }
                    alt=""
                  />
                )}
              </Step>
            )
          })}
        </ProgressBar>
      </div>
    )
  }
}

export default ProgressLine
