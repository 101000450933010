import React from 'react'
import './ContactInfo.scss'

class ContactInfo extends React.Component {
  render() {
    return (
      <div className="ContactInfo">
        <a
          href="mailto:syncin@cleared.dk"
          target="_blank"
          rel="noopener noreferrer">
          syncin@cleared.dk
        </a>
      </div>
    )
  }
}

export default ContactInfo
