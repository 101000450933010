import * as Sentry from '@sentry/browser'

import BorderlessButton from 'components/BorderlessButton/index'
import Button from 'components/Button/Button'
import CheckBox from 'components/CheckBox/CheckBox'
import Phone from 'components/Phone/Phone'
import React from 'react'
import TextInput from 'components/TextInput/index'
import axios from 'axios'

function validateEmail(email) {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const apiURL = process.env.REACT_APP_API_URL

class GuesstimatorPageThree extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      termsAccepted: false,
      errorMessage: '',
    }
  }

  get validTextInput() {
    const firstName = this.answers.firstName
    const lastName = this.answers.lastName

    const validFirstName = firstName && firstName.length > 0
    const validLastName = lastName && lastName.length > 0

    return validFirstName && validLastName && this.validEmail > 0
  }

  get validEmail() {
    const email = this.answers.email

    return email && validateEmail(email)
  }

  handleTermsOnChange = () => {
    this.setState({
      termsAccepted: !this.state.termsAccepted,
    })
  }

  get answers() {
    return this.props.answers || {}
  }

  get onChange() {
    return this.props.onChange
  }

  get termsAccepted() {
    return this.state.termsAccepted || false
  }

  get song() {
    return this.answers.song || ''
  }

  get phone() {
    return this.answers.phone || ''
  }

  get email() {
    return this.answers.email || ''
  }

  get firstName() {
    return this.answers.firstName || ''
  }

  get lastName() {
    return this.answers.lastName || ''
  }

  get errorMessage() {
    return this.state.errorMessage || ''
  }

  handleSubmit = () => {
    const { onLoad, onSubmit } = this.props
    const { answers } = this
    let {
      song,
      artist,
      description = '',
      productionTypeOther = '',
      termDuration = 0,
      email,
      firstName,
      lastName,
      phone,
      licenseType = {},
      territory = [],
      mediaType = [],
      termType = {},
      productionType = {},
      sceneType = {},
      songVersion = '',
    } = answers

    onLoad()

    productionType = [productionType.value]
    licenseType = [licenseType.value]
    sceneType = sceneType && sceneType.value ? [sceneType.value] : []
    termType = [termType.value]
    mediaType = mediaType.map(({ value }) => value)
    const mediaTypeOther = answers['mediaTypeOther']

    const territories = territory.map(({ value }) => value)
    const fullName = firstName + ' ' + lastName

    const json = {
      email,
      phone,
      songVersion,
      fullName,
      termType,
      termValue: termDuration,
      sceneType,
      song,
      artist,
      description,
      territories,
      licenseType,
      productionType,
      productionTypeOther,
      mediaType,
      mediaTypeOther,
      enableGuesstimator: true
    }

    axios
      .post(`${apiURL}/deals/create`, json)
      .then((response) => {
        const { data = {} } = response || {}

        onSubmit(data)
      })
      .catch((e) => {
        if (Sentry) {
          Sentry.captureException(e);
        }

        onLoad()
        this.setState({
          errorMessage: 'An error occured, please try again or contact support',
        })
      })
  }

  render() {
    const { prevOnClick } = this.props

    return (
      <div className="GuesstimatorPage" id="GuesstimatorPage-3">
        <div className="description">Information about you</div>
        {this.errorMessage && (
          <div className="error-message">{this.errorMessage}</div>
        )}
        <div className="question-container">
          <form>
            <TextInput
              placeholder="First name"
              onChange={this.onChange('firstName')}
              inputClassName="question-text"
              wrapperClassName="question-wrapper"
              initialValue={this.firstName}
              value={this.firstName}
            />
            <TextInput
              placeholder="Last name"
              onChange={this.onChange('lastName')}
              inputClassName="question-text"
              wrapperClassName="question-wrapper"
              initialValue={this.lastName}
              value={this.lastName}
            />
            <TextInput
              placeholder="Email"
              onChange={this.onChange('email')}
              inputClassName="question-text"
              wrapperClassName="question-wrapper"
              initialValue={this.email}
              value={this.email}
              error={!this.validEmail}
            />
            <Phone
              className="question-wrapper"
              value={this.phone}
              onChange={this.onChange('phone')}
            />

            <div className="terms-and-conditions">
              <CheckBox
                className="checkbox"
                onCheckboxChanged={this.handleTermsOnChange}
              />

              <p>
                I hereby agree to the{' '}
                <a href="/terms" target="_blank">
                  Terms of Use
                </a>{' '}
                and{' '}
                <a href="/privacy" target="_blank">
                  Privacy Policy
                </a>
                .
              </p>
            </div>

            <div className="button-container">
              <BorderlessButton className="button" onClick={prevOnClick}>
                Previous
              </BorderlessButton>
              <Button
                onClick={() => this.handleSubmit()}
                className="button"
                disabled={!this.termsAccepted || !this.validTextInput}>
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default GuesstimatorPageThree
