import { LICENSE_TYPE } from '../../Guesstimator/data'
import React from 'react'
import Select from 'components/Select'

export default class LicenseType extends React.Component {
  get licenseType() {
    return this.answers.licenseType
  }

  get onChange() {
    return this.props.onChange
  }

  get answers() {
    return this.props.answers || {}
  }

  render() {
    const { placeholder = 'License type' } = this.props

    return (
      <React.Fragment>
        <Select
          placeholder={placeholder}
          className="question-wrapper"
          multiSelect={false}
          options={LICENSE_TYPE}
          value={this.licenseType}
          onChange={this.onChange('licenseType')}
        />
      </React.Fragment>
    )
  }
}
