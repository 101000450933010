import { MUSIC_USE } from '../../Guesstimator/data'
import React from 'react'
import Select from 'components/Select'
import TextInput from 'components/TextInput/index'

export default class MusicUse extends React.Component {
  get value() {
    return this.answers.musicUse
  }

  get valueOther() {
    return this.answers.musicUseOther || ''
  }

  get onChange() {
    return this.props.onChange
  }

  get answers() {
    return this.props.answers || {}
  }

  get productionType() {
    return this.answers.productionType
  }

  get displayOtherInput() {
    return this.value && this.value.some((item) => item.value === 'other')
  }

  render() {
    if (!this.productionType) {
      return null
    }

    if (this.productionType.value === 'marketing') {
      return null
    }

    return (
      <React.Fragment>
        <Select
          onChange={this.onChange('musicUse')}
          placeholder="Music use (Optional)"
          className="question-wrapper"
          initialValue={this.value}
          options={MUSIC_USE}
          value={this.value}
          multiSelect={true}
          disabled={this.productionType ? false : true}
        />

        {this.displayOtherInput && (
          <div className="music-use">
            <TextInput
              onChange={this.onChange('musicUseOther')}
              placeholder="Describe your use of the music"
              initialValue={this.valueOther}
              wrapperClassName="question-wrapper"
              value={this.valueOther}
            />
          </div>
        )}
      </React.Fragment>
    )
  }
}
