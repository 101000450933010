import './Offer.scss'

import MediaQuery from 'react-responsive'
import React from 'react'
import RoundButton from 'components/RoundButton/index'

const formatPrice = price => {
  if (!price) {
    return '';
  }

  return new Intl.NumberFormat('da-DK', { 
    style: 'currency', 
    currency: 'DKK',
    maximumSignificantDigits: 2
  }).format(price);
}

class Offer extends React.Component {
  get guesstimate() {
    return this.props.guesstimate || {}
  }

  get answers() {
    return this.props.answers;
  }

  redirect =() =>  {
    const { dealLink } = this.guesstimate;

    window.location = dealLink;
  }

  render() {
    const { answers = {}, guesstimate: { price = {}, termValue } = {} } = this
    const {
      productionType = {},
      mediaType = [],
      termType = {},
      territory: territories = [],
      musicUse = [],
      licenseType = {},
      songVersion,
      musicUseOther,
      artist,
      song
    } = answers

    return (
      <div className="Guesstimator">
        <section className="GuesstimatorOffer">
          <div className="GuesstimatorOffer-content">
            <div className="top-container">
              <div className="header">
                <h1>{artist} - {song}</h1>
              </div>

              <div className="guesstimate-container">
                <p className="guesstimate-text">Guesstimate: </p>
                <div className="guesstimate">
                  <span className="low">{formatPrice(price.low)}</span>
                  <span className="separator">-</span>
                  <span className="high">{formatPrice(price.high)}</span>
                </div>
              </div>

            </div>

            <div className="bottom">
              <div className="information">
                <table>
                  <tbody>
                  <MediaQuery maxDeviceWidth={1024}>
                    {(matches) =>
                      matches ? 
                      <React.Fragment>
                        <tr>
                          <th>Artist:</th>
                          <td>{ artist }</td>
                        </tr>
                        <tr>
                          <th>Song:</th>
                          <td>{ song }</td>
                        </tr>
                      </React.Fragment>
                      : null
                    }
                  </MediaQuery>
                    <tr>
                      <th>Production:</th>
                      <td>{ productionType.label }</td>
                    </tr>
                    <tr>
                      <th>Media:</th>
                      <td>
                        <span>{ mediaType.map(({ label }) => label).join(', ') }</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Term:</th>
                      <td>
                        { termValue &&
                          (
                            <span style={ { paddingRight: '5px ' } }>{termValue } - </span>
                          )
                        }
                        { termType.label }
                      </td>
                    </tr>
                    <tr>
                      <th>Territory:</th>
                      <td>
                        <span>{ territories.map(({ label }) => label).join(', ') }</span>
                      </td>
                    </tr>

                    { musicUse && musicUse.length > 0 &&
                      <tr>
                        <th>Music Use:</th>
                        <td>
                          <span>
                            { musicUse.map(({ label }) => label).join(', ') }
                          </span>
                          { musicUseOther &&
                            (
                              <i style={ { paddingLeft: '5px ' } }> - {musicUseOther }</i>
                            )
                          }
                        </td>
                      </tr>
                    }
                    { songVersion && songVersion.length > 0 &&
                      <tr>
                        <th>Song Version:</th>
                        <td>
                          <span>{ songVersion }</span>
                        </td>
                      </tr>
                    }
                    { licenseType && licenseType.label &&
                      <tr>
                        <th>License Type:</th>
                        <td>
                          <span>{ licenseType.label }</span>
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>

              <div className="button-container">
                <p>
                  <b>Get an offer using the Synchronizer</b>
                </p>
                <RoundButton className="next-button-wrapper" onClick={ this.redirect} />
              </div>

              <div className="legal-text">
                <p>
                  * The guesstimate reflects the total price for all synchronization rights. The guesstimate is an educated guess based on historical licensing data and price dependent variables determined by SyncIn. It’s not a binding offer. SyncIn cannot be held liable in situations where the above amount differs from a later offer.
                </p>
              </div>

            </div>
          </div>

          <div className="sync-image-wrapper">
            <img
              src={ require('assets/images/synchronizer-background.svg') }
              alt=""
            />
          </div>
        </section>
      </div>
    )
  }
}

export default Offer
