import './FrontPage.scss'

import ContactInfo from 'components/ContactInfo/ContactInfo'
import ModalVideo from 'react-modal-video'
import React from 'react'
import RoundButton from 'components/RoundButton/RoundButton'
import TextLoop from 'react-text-loop'
import disableScroll from 'disable-scroll'

class FrontPage extends React.Component {
  constructor(props) {
    super(props)
    this.closeModal = this.closeModal.bind(this)
    this.state = {
      isOpen: false,
    }
    this.openModal = this.openModal.bind(this)
  }
  openModal() {
    disableScroll.on()
    this.setState({ isOpen: true })
  }

  closeModal() {
    disableScroll.off()
    this.setState({ isOpen: false })
  }

  render() {
    return (
      <React.Fragment>
        <section id="FrontPage" className="FrontPage">
          {' '}
          <div className="content">
            <div className="text">
              <div className="content-block">
                <h1>
                  Bring{' '}
                  <TextLoop
                    children={['music', 'emotions', 'memories', 'passion']}
                    interval={3000}
                    className="loop-color"
                  />{' '}
                  to your story
                </h1>
                <h2>
                  Music Licensing is confusing. We make it easy.
                </h2>
                <div className="get-guesstimate">
                  <h2>
                    Get free price guesstimates on licensing of music today
                  </h2>
                  <RoundButton
                    className="get-guesstimate-button"
                    navToPath="/#Guesstimator"
                    onClick={() => null}
                  />
                </div>
              </div>
            </div>

            <div>
              <ModalVideo
                channel="youtube"
                autoplay={1}
                isOpen={this.state.isOpen}
                videoId="8ZvKHoNBSlo"
                onClose={this.closeModal}></ModalVideo>

              <div onClick={this.openModal} className="video">
                <img
                  className="syncin-video"
                  src={require('assets/images/syncin-video.svg')}
                  alt=""
                />
                <img
                  className="playbutton"
                  src={require('assets/images/playbutton.svg')}
                  alt=""
                />
              </div>
            </div>
          </div>
          <ContactInfo />
          <div className="down-button">
            <a className="arrow-container" href="#Guesstimator">
              <div className="down-arrow"></div>
            </a>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default FrontPage
