const endorsements = [
  {
    index: 0,
    content:
      'SyncIn is the service you never knew you needed. They make the process of estimating, negotiating, and clearing music rights smooth and easy. Best of all - they save me time and money. I don’t think I’ll work without them again.',
    endorser: 'Michelle Faeroe',
    job: 'Producer @ The Bunch',
    image: require('assets/images/testimonialImages/michelle.jpg'),
  },
  {
    index: 1,
    content:
      'The guesstimator is something the industry has been missing without it even knowing. It gives us the transparency we’ve always wanted. It’s easy to use and with its fast response, it eases the workflow and creative process. It should basically be bookmarked by all agencies out there.',
    endorser: 'Trine Keller',
    job: 'Head of Strategy @ Anothercph',

    image: require('assets/images/testimonialImages/trine.jpg'),
  },
  {
    index: 2,
    content:
      "In connection with the production of a TV series, I have greatly benefited from SyncIn's expertise in music rights. We wanted some specific numbers for our series and in that connection they moved quickly and efficiently which meant we had a financial overview within a few days. I will definitely use their service again and I highly recommend SyncIn.",
    endorser: 'Sophie Hoffenberg',
    job: 'Producer @ Drive Studios',
    image: require('assets/images/testimonialImages/sophie.jpg'),
  },
]

export default endorsements
