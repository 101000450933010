import { MEDIA_TYPES } from './data'
import { filterItems } from './utils'

const marketingMediaTypes = [
  'all_media',
  'trailer',
  'tv',
  'web',
  'radio',
  'cinema',
  'podcast',
  'lobby_screens',
  'social_media',
  'out_of_home',
  'other',
]

export const MARKETING_MEDIA_TYPES = filterItems(
  marketingMediaTypes,
  MEDIA_TYPES
)

const seriesMediaTypes = ['all_media', 'trailer', 'tv', 'web', 'vod', 'other']

export const SERIES_MEDIA_TYPES = filterItems(seriesMediaTypes, MEDIA_TYPES)

const movieMediaTypes = [
  'all_media',
  'trailer',
  'tv',
  'web',
  'cinema',
  'festival',
  'vod',
  'social_media',
  'other',
]

export const MOVIE_MEDIA_TYPES = filterItems(movieMediaTypes, MEDIA_TYPES)

const documentaryMediaTypes = [
  'all_media',
  'trailer',
  'tv',
  'web',
  'cinema',
  'festival',
  'vod',
  'social_media',
  'other',
]

export const DOCUMENTARY_MEDIA_TYPES = filterItems(
  documentaryMediaTypes,
  MEDIA_TYPES
)

const gamesMediaTypes = ['all_media', 'trailer', 'web', 'video_game', 'other']

export const GAMES_MEDIA_TYPES = filterItems(gamesMediaTypes, MEDIA_TYPES)

export const OTHER_MEDIA_TYPES = MEDIA_TYPES

export const FILTERED_MEDIA_TYPES = {
  marketing: MARKETING_MEDIA_TYPES,
  series: SERIES_MEDIA_TYPES,
  movie: MOVIE_MEDIA_TYPES,
  documentary: DOCUMENTARY_MEDIA_TYPES,
  games: GAMES_MEDIA_TYPES,
  other: OTHER_MEDIA_TYPES,
}
