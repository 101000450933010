import './App.scss'

import { Route, Switch, withRouter } from 'react-router-dom'

import About from '../routes/About/About'
import FAQ from 'routes/FAQ/FAQ'
import FrontPage from '../routes/FrontPage/FrontPage'
import Guesstimator from '../routes/Guesstimator/Guesstimator'
import HowDoesItWork from 'routes/HowDoesItWork'
import Navbar from '../components/Header/Header'
import Pricing from 'routes/Pricing/Pricing'
import Privacy from '../routes/Privacy/Privacy'
// import Projects from 'routes/Projects/Projects'
import React from 'react'
import Synchronizer from 'routes/Synchronizer/Synchronizer'
import Terms from '../routes/Terms/Terms'
import Testimonials from 'routes/Testimonials'

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      currentPage: 0,
      direction: 'down',
      showOffer: false,
      productActive: 0,
      clearFlow: {
        currentPage: 0,
        direction: 'down',
        showOffer: false,
      },
    }
  }

  render() {
    return (
      <div className="App">
        <Navbar />

        <Switch>
          <Route path="/terms">
            <Terms />
          </Route>

          <Route path="/privacy">
            <Privacy />
          </Route>

          <Route path="/clear/:token">
            <div className="section" data-anchor="synchronizer">
              <Synchronizer />
            </div>
          </Route>

          <Route path="/">
            <React.Fragment>
              <div className="section" data-anchor="FrontPage">
                <FrontPage />
              </div>

              <div className="section" data-anchor="guestimator">
                <Guesstimator />
              </div>

              <div className="section" data-anchor="about">
                <About />
              </div>
              {/* <div className="section" data-anchor="projects">
                <Projects />
              </div> */}
              <div className="section" data-anchor="testimonials">
                <Testimonials />
              </div>
              <div className="section" data-anchor="how-does-it-work">
                <HowDoesItWork />
              </div>
              <div className="section" data-anchor="pricing">
                <Pricing />
              </div>
              <div className="section" data-anchor="faq">
                <FAQ />
              </div>
            </React.Fragment>
          </Route>
        </Switch>
      </div>
    )
  }
}

export default withRouter(App)
