import React from 'react'
import './CheckBox.scss'
import classNames from 'classnames'

class CheckBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: false,
    }
  }

  isChecked() {
    return this.state.checked
  }

  handleCheckboxChange(event, onCheckboxChanged) {
    this.setState({
      checked: event.target.checked,
    })
    if (onCheckboxChanged) {
      onCheckboxChanged()
    }
  }

  render() {
    const { onCheckboxChanged, className } = this.props

    return (
      <div className={classNames('CheckBox', className)}>
        <label className="container">
          <input
            type="checkbox"
            checked={this.state.checked}
            onChange={(e) => this.handleCheckboxChange(e, onCheckboxChanged)}
          />
          <span className="checkmark"></span>
        </label>
      </div>
    )
  }
}

export default CheckBox
