import React from 'react'
import TextInput from 'components/TextInput/index'
import Select from 'components/Select'
import { TERMS } from '../../data'

export default class Term extends React.Component {
  get value() {
    return this.answers.termType
  }

  get valueOther() {
    return this.answers.termDuration || ''
  }

  get onChange() {
    return this.props.onChange
  }

  get answers() {
    return this.props.answers || {}
  }

  get mediaType() {
    return this.answers.mediaType
  }

  get displayOtherInput() {
    return this.value && ['month', 'year'].includes(this.value.value)
      ? 'block'
      : 'none'
  }

  render() {
    return (
      <React.Fragment>
        <Select
          onChange={this.onChange('termType')}
          placeholder="Term of licensing"
          className="question-wrapper"
          initialValue={this.value}
          options={TERMS}
          value={this.value}
          disabled={this.mediaType ? false : true}
        />

        <div
          className="termDuration"
          style={{
            display: this.displayOtherInput,
          }}>
          <TextInput
            textType={'number'}
            onChange={this.onChange('termDuration')}
            placeholder="Specify number of years / months"
            initialValue={this.valueOther}
            wrapperClassName="question-wrapper"
            value={this.valueOther}
          />
        </div>
      </React.Fragment>
    )
  }
}
