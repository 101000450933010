import BorderlessButton from 'components/BorderlessButton/index'
import Button from 'components/Button/Button'
import LicenseType from '../components/LicenseType'
import React from 'react'
import TextInput from 'components/TextInput/index'

class SynchronizerPageOne extends React.Component {
  get answers() {
    return this.props.answers
  }

  get onChange() {
    return this.props.onChange
  }

  get valid() {
    const {
      companyName = '',
      productService = '',
      campaignDescriptionAndUse = '',
      advertisementChannelOther = '',
    } = this.answers

    return (
      companyName.length > 0 &&
      productService.length > 0 &&
      campaignDescriptionAndUse.length > 0 &&
      advertisementChannelOther.length > 0
    )
  }

  render() {
    const { nextOnClick, prevOnClick, answers } = this.props

    return (
      <div className="SynchronizerPage" id="SynchronizerMarketingPage-1">
        <div className="meta-description">
          <h1>Synchronizer</h1>
        </div>
        <div className="description">Information regarding your production</div>
        <div className="question-container">
          <TextInput
            placeholder="Name the client/brand"
            inputClassName="question-text"
            wrapperClassName="question-wrapper"
            onChange={this.onChange('companyName')}
            initialValue={answers.companyName || ''}
          />

          <TextInput
            placeholder="Specify product/service"
            inputClassName="question-text"
            wrapperClassName="question-wrapper"
            onChange={this.onChange('productService')}
            initialValue={answers.productService || ''}
          />

          <TextInput
            textType={'textarea'}
            placeholder="Campaign description and use of music"
            inputClassName="question-text"
            wrapperClassName="question-wrapper"
            onChange={this.onChange('campaignDescriptionAndUse')}
            initialValue={answers.campaignDescriptionAndUse || ''}
          />

          <TextInput
            placeholder="Specify advertisement channels (e.g. Youtube, Facebook)"
            onChange={this.onChange('advertisementChannelOther')}
            initialValue={answers.advertisementChannelOther || ''}
            wrapperClassName="question-wrapper"
          />

          <LicenseType 
            answers={this.answers}
            placeholder="Version"
            onChange={this.onChange}
          />

          <div className="button-container">
            <BorderlessButton className="button" onClick={prevOnClick}>
              Previous
            </BorderlessButton>
            <Button
              onClick={nextOnClick}
              className="button"
              disabled={!this.valid}>
              Next
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default SynchronizerPageOne
