import './HowDoesItWork.scss'

import ContactInfo from 'components/ContactInfo/ContactInfo'
import MediaQuery from 'react-responsive'
import ProgressLine from 'components/ProgressLine/ProgressLine'
import React from 'react'
import classNames from 'classnames'

class HowDoesItWork extends React.Component {
  constructor(props) {
    super(props)
    this.handleOnHover = this.handleOnHover.bind(this)
    this.state = {
      currentProgressLineIndex: 0,
    }
  }

  handleOnHover(index) {
    this.setState({
      currentProgressLineIndex: index,
    })
  }

  computeDescriptions() {
    return (
      <div className="progress-line-descriptions" key="descriptions">
        <span
          className={classNames("progress-line-description",{
            active: this.state.currentProgressLineIndex >= 0
          })}
          style={{
            color: this.state.currentProgressLineIndex >= 0 ? 'black' : 'gray',
          }}>
            Use the Guesstimator to get an educated guess on what it costs to license specific music tracks? You can then plan and budget with the music at all times. Or pitch music options during a creative process. The guesstimate calculates what price you can expect music owners to charge for the use. Know more at <a href="/#FAQ" target="_blank">
                <b>FAQ</b>
              </a>.
        </span>
        <span
          className={classNames("progress-line-description", {
            active: this.state.currentProgressLineIndex >= 1
          })}
          style={{
            color: this.state.currentProgressLineIndex >= 1 ? 'black' : 'gray',
          }}>
          Share more information on the Synchronizer and get an offer from the music owners. SyncIn connects to the owners - the world over - and delivers a price offer directly to your inbox in no time. 

          Check out our <a href="/#Pricing" target="_blank">
                <b>pricing</b>
              </a>!
        </span>
        <span
          className={classNames("progress-line-description", {
            active: this.state.currentProgressLineIndex >= 2
          })}
          style={{
            color: this.state.currentProgressLineIndex >= 2 ? 'black' : 'gray',
          }}>
            With an offer we can start the negotiation process and secure the license within your budget. When both parties have agreed you will receive a contract and invoice via email. 

            From here it’s just the formalites.
        </span>
        <span
          className={classNames("progress-line-description", {
            active: this.state.currentProgressLineIndex >= 3
          })}
          style={{
            color: this.state.currentProgressLineIndex >= 3 ? 'black' : 'gray',
          }}>
            Now you can SyncIn the track with your production and Empower your it with music that evokes real feelings.

            Our use the <a href="/#Guesstimator" target="_blank">
                <b>Guesstimator</b>
              </a> again
        </span>
      </div>
    )
  }

  computeTitles() {
    return (
      <div className="progress-line-titles" key="tiles">
        <span
          className={classNames("progress-line-title")}
          style={{
            color: this.state.currentProgressLineIndex >= 0 ? 'black' : 'gray',
          }}>
          <b>Free price Guesstimate</b>
        </span>
        <span
          className={classNames("progress-line-title")}
          style={{
            color: this.state.currentProgressLineIndex >= 1 ? 'black' : 'gray',
          }}>
          <b>Get an offer from the music owners </b>
        </span>
        <span
          className={classNames("progress-line-title")}
          style={{
            color: this.state.currentProgressLineIndex >= 2 ? 'black' : 'gray',
          }}>
          <b>Negotiation, Contract & Payment</b>
        </span>
        <span
          className={classNames("progress-line-title")}
          style={{
            color: this.state.currentProgressLineIndex >= 3 ? 'black' : 'gray',
          }}>
          <b>
            You are now <i>synced</i> in
          </b>
        </span>
      </div>
    )
  }

  computeDesktopLayout() {
    return [
      this.computeTitles(),

      <div className="progress-line-container" key="container">
        <ProgressLine
          className="progress-line"
          onMouseEnter={this.handleOnHover}
          currIndex={this.state.currentProgressLineIndex}
          disable={true}
          enableHover={true}
          progressLineWidth={'85%'} // offsets the progress-line-title and progress-line-description width
          amountOfSteps={4}
        />
      </div>,

      this.computeDescriptions(),
    ]
  }

  computeMobileLayout() {
    const progressLine = (
      <div className="progress-line-container" key="container">
        <ProgressLine
          className="progress-line"
          onMouseEnter={this.handleOnHover}
          currIndex={this.state.currentProgressLineIndex}
          disable={true}
          enableHover={true}
          progressLineWidth={'100%'} // offsets the progress-line-title and progress-line-description width
          amountOfSteps={4}
        />
      </div>
    )

    return (
      <div style={{ height: '100%' }}>
        {progressLine}

        <div className="mobile-wrapper">
          <div className="progress-line-title-and-description">
            <span
              className={classNames("progress-line-title", {
                active: this.state.currentProgressLineIndex >= 0
              })}
              style={{
                color:
                  this.state.currentProgressLineIndex >= 0 ? 'black' : 'gray',
              }}>
              <b>Free price Guesstimate</b>
            </span>
            <span
              className={classNames("progress-line-description", {
                active: this.state.currentProgressLineIndex >= 0
              })}
              style={{
                color:
                  this.state.currentProgressLineIndex >= 0 ? 'black' : 'gray',
              }}>
                Use the Guesstimator to get an educated guess on what it costs to license specific music tracks? You can then plan and budget with the music at all times. Or pitch music options during a creative process. The guesstimate calculates what price you can expect music owners to charge for the use. Know more at <a href="/#FAQ" target="_blank">
                    <b>FAQ</b>
                  </a>.
            </span>
          </div>

          <div className="progress-line-title-and-description">
            <span
              className={classNames("progress-line-title", {
                active: this.state.currentProgressLineIndex >= 1
              })}
              style={{
                color:
                  this.state.currentProgressLineIndex >= 1 ? 'black' : 'gray',
              }}>
              <b>Get an offer from the music owners</b>
            </span>
            <span
              className={classNames("progress-line-description", {
                active: this.state.currentProgressLineIndex >= 1
              })}
              style={{
                color:
                  this.state.currentProgressLineIndex >= 1 ? 'black' : 'gray',
              }}>
              Share more information on the Synchronizer and get an offer from the music owners. SyncIn connects to the owners - the world over - and delivers a price offer directly to your inbox in no time. 

              Check out our <a href="/#Pricing" target="_blank">
                    <b>pricing</b>
                  </a>!
            </span>
          </div>

          <div className="progress-line-title-and-description">
            <span
              className={classNames("progress-line-title", {
                active: this.state.currentProgressLineIndex >= 2
              })}
              style={{
                color:
                  this.state.currentProgressLineIndex >= 2 ? 'black' : 'gray',
              }}>
              <b>Negotiation, Contract & Payment</b>
            </span>
            <span
              className={classNames("progress-line-description", {
                active: this.state.currentProgressLineIndex >= 2
              })}
              style={{
                color:
                  this.state.currentProgressLineIndex >= 2 ? 'black' : 'gray',
              }}>
                With an offer we can start the negotiation process and secure the license within your budget. When both parties have agreed you will receive a contract and invoice via email. 

                From here it’s just the formalites.
            </span>
          </div>

          <div className="progress-line-title-and-description">
            <span
              className={classNames("progress-line-title", {
                active: this.state.currentProgressLineIndex >= 3
              })}
              style={{
                color:
                  this.state.currentProgressLineIndex >= 3 ? 'black' : 'gray',
              }}>
              <b>
                You are now <i>synced</i> in
              </b>
            </span>
            <span
              className={classNames("progress-line-description", {
                active: this.state.currentProgressLineIndex >= 3
              })}
              style={{
                color:
                  this.state.currentProgressLineIndex >= 3 ? 'black' : 'gray',
              }}>
                Now you can SyncIn the track with your production and Empower your it with music that evokes real feelings.

                Our use the <a href="/#Guesstimator" target="_blank">
                    <b>Guesstimator</b>
                  </a> again
            </span>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <section className="HowDoesItWork" id="HowDoesItWork">
        <div className="how-does-it-work-wrapper">
          <div className="heading">
            <h1>
              <b>Licensing Service</b>
            </h1>
          </div>
          <div className="meta-text-content">
            <p>
              As a syncin.io customer, you no longer have to be concerned with music licensing hitten pricing, ridget process, laws and rights. 
            </p>
            <p>
              Syncin mediates with the music owners and assist you throughout the process until the license is assured.
            </p>
          </div>

          <MediaQuery maxDeviceWidth={1024}>
            {(matches) =>
              matches ? this.computeMobileLayout() : this.computeDesktopLayout()
            }
          </MediaQuery>
        </div>
        <ContactInfo />
      </section>
    )
  }
}

export default HowDoesItWork
