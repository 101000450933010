import './WhatWeKnow.scss'

import React from 'react'
import RoundButton from 'components/RoundButton/index'

const formatPrice = price => {
  if (!price) {
    return '';
  }

  return new Intl.NumberFormat('da-DK', { 
    style: 'currency', 
    currency: 'DKK',
    maximumSignificantDigits: 2
  }).format(price);
}

class WhatWeKnow extends React.Component {
  render() {
    const { answers, onProceed } = this.props
    const {
      song,
      artist,
      productionType,
      mediaType,
      termType,
      termValue,
      territories,
      musicUse = [],
      licenseType,
      songVersion,
      musicUseOther,
      guesstimateLow,
      guesstimateHigh
    } = answers

    return (
      <section id="WhatWeKnow" className="WhatWeKnow">
        <div className="header">
          <h1>Synchronizer</h1>
        </div>
        <div className="sub-header-text">

          <p>Thank you for choosing us in your quest of licensing music.</p>

          <p>This is what we know so far: </p>
        </div>

        <div className="information">
          <table>
            <tbody>
              {guesstimateLow &&
                <tr>
                  <th>Guesstimate:</th>
                  <td>
                    <span>
                      {formatPrice(guesstimateLow)}
                    </span>
                    <span className="m-r-5 m-l-5">
                      -
                    </span>
                    <span>
                      {formatPrice(guesstimateHigh)}
                    </span>
                  </td>
                </tr>
              }
              <tr>
                <th>Artist:</th>
                <td>{ artist }</td>
              </tr>
              <tr>
                <th>Song:</th>
                <td>{ song }</td>
              </tr>
              <tr>
                <th>Production:</th>
                <td>{ productionType.label }</td>
              </tr>
              <tr>
                <th>Media:</th>
                <td>
                  <span>{ mediaType.map(({ label }) => label).join(', ') }</span>
                </td>
              </tr>
              <tr>
                <th>Term:</th>
                <td>
                  { termValue &&
                    (
                      <span style={ { paddingRight: '5px ' } }>{termValue } - </span>
                    )
                  }
                  { termType.label }
                </td>
              </tr>
              <tr>
                <th>Territory:</th>
                <td>
                  <span>{ territories.map(({ label }) => label).join(', ') }</span>
                </td>
              </tr>

              { musicUse && musicUse.length > 0 &&
                <tr>
                  <th>Music Use:</th>
                  <td>
                    <span>
                      { musicUse.map(({ label }) => label).join(', ') }
                    </span>
                    { musicUseOther &&
                      (
                        <i style={ { paddingLeft: '5px ' } }> - {musicUseOther }</i>
                      )
                    }
                  </td>
                </tr>
              }
              { songVersion && songVersion.length > 0 &&
                <tr>
                  <th>Song Version:</th>
                  <td>
                    <span>{ songVersion }</span>
                  </td>
                </tr>
              }
              { licenseType && licenseType.label &&
                <tr>
                  <th>License Type:</th>
                  <td>
                    <span>{ licenseType.label }</span>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>

        <div className="proceed-text">
          <p>SyncIn mediates between you and the music owners to get you a price offer in now time.</p>
          
          <p>By using the Synchronizer you accept the service fee starting at: 1250 DKK. &nbsp; See our <a href="https://syncin.io#Pricing" target="_blank" rel="noopener noreferrer">
            pricing plan
          </a> for more details. </p>
        </div>

        <div className="button-container">
          <p>
            <b>Proceed to the Synchronizer</b>
          </p>
          <RoundButton className="next-button-wrapper" onClick={onProceed} />
        </div>

        <div className="sync-image-wrapper">
          <img
            src={require('assets/images/synchronizer-background.svg')}
            alt=""
          />
        </div>
      </section>
    )
  }
}

export default WhatWeKnow
