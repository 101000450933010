import React, { Component } from 'react'
import './Header.scss'
import { ReactComponent as LogoSvg } from '../../assets/logo.svg'
import classNames from 'classnames'
import Navigation from 'components/Navigation/Navigation'
import { withRouter } from 'react-router-dom'

class Header extends Component {
  constructor(props) {
    super(props)
    this.hideNavBar = this.hideNavBar.bind(this)

    this.state = {
      prevScrollpos: window.pageYOffset,
      visible: true,
      transparent: true,
    }
  }

  handleScroll = () => {
    const {
      location: { pathname },
    } = this.props
    const { prevScrollpos } = this.state

    const currentScrollPos = window.pageYOffset
    const visible =
      (window.pageYOffset > window.innerHeight / 1.5 ||
        window.pageYOffset < 500) &&
      (prevScrollpos > currentScrollPos || currentScrollPos < 500)

    let transparent = false

    const isSynchronizer = pathname.includes('/clear')
    const isTerms = pathname.includes('/terms')
    const isPrivacy = pathname.includes('/privacy')

    if (window.pageYOffset < window.innerHeight) {
      if (!isSynchronizer) {
        transparent = true
      }
    }

    if (window.pageYOffset > 80 && (isTerms || isPrivacy)) {
      transparent = false
    }

    this.setState({
      prevScrollpos: currentScrollPos,
      visible,
      transparent,
    })
  }

  hideNavBar() {
    this.setState({
      visible: false,
    })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  render() {
    return (
      <div className="Header">
        <nav
          style={{
            backgroundColor: this.state.transparent
              ? 'rgba(35, 37, 45,0.00)'
              : 'rgba(35, 37, 45,1)',
            boxShadow: this.state.transparent
              ? 'none'
              : '0px 4px 4px rgba(0, 0, 0, 0.1)',
          }}
          className={classNames('navbar', {
            'navbar--hidden': !this.state.visible,
          })}>
          <div className={classNames('Header')}>
            <a href="/#FrontPage">
              <LogoSvg className="logo" onClick={() => null} />
            </a>
          </div>
        </nav>
        <div
          className={classNames('burger-bar', {
            'burger-bar--hidden': !this.state.visible,
          })}>
          <Navigation onNavigate={this.hideNavBar} />
        </div>
      </div>
    )
  }
}

export default withRouter(Header)
