import { COUNTRIES, REGIONS, TERRITORY_SHORT_LIST } from '../../data'

import BorderlessButton from 'components/BorderlessButton/index'
import Button from 'components/Button/Button'
import MediaType from './MediaType'
import ProductionType from './ProductionType'
import React from 'react'
import Select from 'components/Select'
import Term from './Term'

class GuesstimatorPageTwo extends React.Component {
  get answers() {
    return this.props.answers || {}
  }

  get termType() {
    return this.answers.termType || []
  }

  get territory() {
    return this.answers.territory
  }

  get termDuration() {
    return this.answers.termDuration
  }

  get sceneType() {
    return this.answers.sceneType
  }

  get onChange() {
    return this.props.onChange
  }

  get valid() {
    const {
      productionType,
      mediaType = [],
      territory = [],
      termDuration,
      productionTypeOther,
      mediaTypeOther,
    } = this.answers

    const termTypeObject = this.answers.termType || {}
    const { value: termType } = termTypeObject

    if (!productionType || !mediaType || !territory) {
      return false
    }

    if (!mediaType.length || !territory.length) {
      return false
    }

    if (!termType) {
      return false
    }

    if (productionType.value === 'other' && !productionTypeOther) {
      return false
    }

    if (mediaType.some((item) => item.value === 'other') && !mediaTypeOther) {
      return false
    }

    if (
      !productionType.value === 'marketing'
    ) {
      return false
    }

    if (['month', 'year'].includes(termType) && !termDuration) {
      return false
    }

    return true
  }

  render() {
    const { nextOnClick, prevOnClick } = this.props
    const answers = this.answers

    return (
      <div className="GuesstimatorPage" id="GuesstimatorPage-2">
        <div className="description">Information on the use</div>
        <div className="question-container">
          <ProductionType onChange={this.onChange} answers={answers} />

          <MediaType onChange={this.onChange} answers={answers} />

          <Term onChange={this.onChange} answers={answers} />

          <Select
            onChange={this.onChange('territory')}
            placeholder="Territories"
            className="question-wrapper"
            initialValue={this.value}
            multiSelect={true}
            options={[
              {
                label: 'Popular',
                options: TERRITORY_SHORT_LIST
              },
              {
                label: 'Regions',
                options: REGIONS,
              },
              {
                label: 'Countries',
                options: COUNTRIES,
              },
            ]}
            value={this.territory}
            disabled={this.termType || !this.termType.length ? false : true}
          />

          <div className="button-container">
            <a href="/#Guesstimator">
              <BorderlessButton className="button" onClick={prevOnClick}>
                Previous
              </BorderlessButton>
            </a>

            <a href="/#Guesstimator">
              <Button
                onClick={nextOnClick}
                className="button"
                disabled={!this.valid}>
                Next
              </Button>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default GuesstimatorPageTwo
