import React from 'react'
import Button from 'components/Button/index'
import './ThankYou.scss'

class ThankYou extends React.Component {
  constructor(props) {
    super(props)
    this.handleScroll = this.handleScroll.bind(this)
    this.navigateToGuesstimator = this.navigateToGuesstimator.bind(this)
  }

  navigateToGuesstimator() {
    this.props.restart()
  }

  handleScroll = () => {
    if (window.pageYOffset > 2.5 * window.innerHeight) {
      this.props.restart()
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  render() {
    const { answers } = this.props
    return (
      <section id="ThankYou" className="GuesstimatorThankYou">
        <div className="content">
          <h1>
            <b>
              Thank <span>you</span>!
            </b>
          </h1>
          <p>
            You will receive an email at <span>{answers['email']}</span> within
            a day with the price guesstimate.
          </p>
          <p>
            If any questions occur, please do not hesitate to contact us at{' '}
            <span>
              <a
                href="mailto:syncin@cleared.dk"
                target="_blank"
                rel="noopener noreferrer">
                syncin@cleared.dk
              </a>
            </span>
          </p>

          <div className="button-container">
            <Button
              className="sync-another-track-button"
              onClick={() => this.navigateToGuesstimator()}>
              New Guesstimate
            </Button>
          </div>
        </div>
      </section>
    )
  }
}

export default ThankYou
