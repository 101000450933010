import React from 'react'
import Button from 'components/Button/index'
import './ThankYou.scss'

import { Redirect } from 'react-router'

class ThankYou extends React.Component {
  constructor(...props) {
    super(...props)

    this.state = {
      redirect: false,
    }
  }

  render() {
    const { answers, contact = {} } = this.props

    if (this.state.redirect) {
      return <Redirect push to="/" />
    }

    return (
      <div
        id="SynchronizerThankYou"
        className="SynchronizerThankYou SynchronizerPage">
        <div className="content">
          <h1>
            <b>
              Thank <span>you</span>!
            </b>
          </h1>
          <p>
            You will receive an email at{' '}
            <span>{answers['email'] || contact.email}</span> once your request
            has been assessed by the music owners.
          </p>
          <p>
            If any questions occur please do not hesitate to contact us at{' '}
            <span>
              <a
                href="mailto:hello@syncin.io"
                target="_blank"
                rel="noopener noreferrer">
                hello@syncin.io
              </a>
            </span>
          </p>

          <div className="button-container">
            <Button
              className="sync-another-track-button"
              onClick={() =>
                this.setState({
                  redirect: true,
                })
              }>
              New Guesstimate
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default ThankYou
