import React from 'react'
import './Select.scss'
import classNames from 'classnames'
import ReactSelect from 'react-select'

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
}

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#ffcc66' : '#fff',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#505050',
    },
    '&:focus': {
      backgroundColor: '#505050',
    },
    '&:active': {
      backgroundColor: '#505050',
    },
  }),
  container: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    outline: 'none',
    outlineWidth: '0px',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: '17.5px',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    paddingLeft: '5px',
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    width: '0px',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    '&:hover': {
      color: 'white',
      backgroundColor: 'transparent',
    },
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    border: '1px solid #505050',
    '&:hover': {
      borderColor: '#ffcc66',
      backgroundColor: '#ffcc66',
    },
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    padding: '5px',
    paddingLeft: '8px',
    paddingBottom: '3px',
    color: 'white',
  }),
  indicatorsContainer: (provided, state) => {
    const isOpenState = state.selectProps.menuIsOpen
      ? {
          svg: {
            transform: 'rotate(180deg)',
          },
        }
      : {}
    return {
      ...provided,
      ...isOpenState,
    }
  },
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: '#282a31',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#ffcc66',
    fontSize: '17.5px',
  }),
  control: (provided, state) => {
    const hoverBorderBottomColor = state.isDisabled
      ? '#c70039'
      : state.hasValue
      ? '#ffcc66'
      : '#c7cbd0'

    return {
      ...provided,
      color: 'rgb(80, 80, 80)',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      border: 0,
      borderRadius: '0px',
      borderBottom: '2px solid #505050',
      outline: 'none',
      outlineWidth: '0px',
      // This line disable the blue border
      boxShadow: 'none',
      '&:hover': {
        borderBottomColor: hoverBorderBottomColor,
      },
      '&:focus': {
        borderBottomColor: hoverBorderBottomColor,
      },
    }
  },
  input: (provided, state) => ({
    ...provided,
    color: 'rgb(80, 80, 80)',
    backgroundColor: 'transparent',
    fontSize: '17.5px',
  }),
}

/**
 * Groups eg:
 *  export const groupedOptions = [
 * {
 *   label: 'Colours',
 *   options: colourOptions,
 * },
 * {
 *   label: 'Flavours',
 *   options: [],
 * },
 * ]
 */

/**
 * Options:
 * - value
 * - label
 * - color
 * - isFixed
 * - isDisabled
 */
const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
)

class Select extends React.Component {
  render() {
    const {
      className,
      options = [],
      placeholder,
      onChange,
      disabled,
      multiSelect = false,
      closeMenuOnSelect,
      ...props
    } = this.props

    return (
      <div className={classNames('Select', className)}>
        <ReactSelect
          className="react-select-container"
          classNamePrefix="react-select"
          styles={customStyles}
          isDisabled={disabled}
          options={options}
          isMulti={multiSelect}
          placeholder={placeholder}
          onChange={onChange}
          formatGroupLabel={formatGroupLabel}
          closeMenuOnSelect={closeMenuOnSelect || !multiSelect}
          {...props}
        />
      </div>
    )
  }
}

export default Select
