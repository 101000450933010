import './index.scss'

import * as Sentry from '@sentry/browser'

import App from './App/App'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

const SENTRY_DSN = 'https://ebe2edcc906c493486cbeebbd611fa60@sentry.io/1788356'

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

if (!isLocalhost && SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
  })
}

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
)
